import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CourseFacadeService } from '@core/facades/course.facade.service';
import { Observable, Subscription } from 'rxjs';
import { CourseTitleComponent } from './course-title/course-title.component';
import { CourseProgressComponent } from './course-progress/course-progress.component';
import { CourseProgressBarComponent } from './course-progress-bar/course-progress-bar.component';

@Component({
    selector: 'sc-course-progress-info',
    templateUrl: './course-progress-info.component.html',
    styleUrls: ['./course-progress-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CourseTitleComponent, CourseProgressComponent, CourseProgressBarComponent]
})
export class CourseProgressInfoComponent implements OnInit, OnDestroy {

  @Input() courseTitle: string;
  courseCompletionTimer: { totalTime: number, completedTime: number };

  private _subscription: Subscription = new Subscription();

  constructor(private _courseFacade: CourseFacadeService,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this._subscription.add(
      this._courseFacade.courseCompletionTimer$.subscribe(v => {
        this.courseCompletionTimer = v;
        this._cdr.detectChanges();
      })
    )

  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

}
