import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sc-player-current-timestamp',
  templateUrl: './player-current-timestamp.component.html',
  styleUrls: ['./player-current-timestamp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayerCurrentTimestampComponent {
  @Input() currentTime: number;
}
