import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnInit,
  Output,
} from '@angular/core';
import {ImageSlide, Slide} from "@models/course.model";
import { ImageProgress, SlideProgress } from '@models/course-progress-data-types.model';
import {Observable} from "rxjs";
import {CourseFacadeService} from "@core/facades/course.facade.service";

@Component({
  selector: 'sc-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent implements OnInit {

  image: ImageSlide;
  imageProgress: ImageProgress;
  volume$: Observable<number>


  @Input() set imageSlide(image: Slide | null) {
    this.image = image as ImageSlide;
    this._cdr.markForCheck();
  }

  @Input() set imageProgressData(progressData: SlideProgress | null) {
    this.imageProgress = {...progressData} as ImageProgress;
    if(!progressData?.slideCompleted) {
      this.imageProgress.slideCompleted = true;
      this.saveProgress.emit(this.imageProgress)
    }
  }

  @Output() saveProgress: EventEmitter<SlideProgress> = new EventEmitter<SlideProgress>();

  constructor(private _cdr: ChangeDetectorRef,
              private _courseFacade: CourseFacadeService) {
  }

  ngOnInit(): void {
    this.volume$ = this._courseFacade.currentVolume$;
  }

  openLink(href: string) {
    window.open(href, '_blank')
  }
}
