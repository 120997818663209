import {Directive, Input} from '@angular/core';

@Directive({
  selector: '[scLink]',
  host: {
    '[style.top]': 'this.scLink.y+"%"',
    '[style.left]': 'this.scLink.x+"%"',
    '[style.width]': 'this.scLink.width+"%"',
    '[style.height]': 'this.scLink.height+"%"',
    '[style.position]': '"absolute"',
  }
})

export class LinkDirective {
  @Input() scLink: any;

  constructor() {
  }

}
