import {Directive, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {BaseComponent} from './base/base.component';

@Directive({
  selector: '[tileDecorator]'
})
export class TileDecoratorDirective implements OnInit {

  @Input() dTileIndex: number;
  @Input() dTileCount: number;
  @Input() dLayout: boolean;

  @ViewChild(BaseComponent) baseComponent: BaseComponent;

  /* @HostListener('window:resize', ['$event']) onResize(event: any) {
     const nativeElement = this._hostElement.nativeElement;
     const clientWidth = nativeElement.clientWidth;
     const clientHeight = nativeElement.clientHeight;

     console.log(clientWidth, clientHeight)
   }*/

  constructor(private _renderer: Renderer2,
              private _hostElement: ElementRef) {

  }

  ngOnInit() {

    this._renderer.addClass(
      this._hostElement.nativeElement,
      'animation-' + (this.dTileIndex + 1)
    );

    this._updateElementSize();

    const correctionX = 0.225;
    const correctionY = 0.025;

    const nativeElement = this._hostElement.nativeElement;
    const clientWidth = 18 + correctionX;
    const clientHeight = 9.8 + correctionY;
    const divider = 2;
    const gutter = 2;
    const unit = 'em';

    //  this._renderer.addClass(nativeElement, `tile-count-${this.tileCount}`);


    switch (this.dTileCount) {
      case 1: {
        this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
        this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
        break;
      }

      case 2: {
        switch (this.dTileIndex) {
          case 0:
            this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
            this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
            break;
          case 1:
            this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
            this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
            break;
        }
        break;
      }

      case 3: {
        if (this.dLayout) {
          switch (this.dTileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
          }
          break;
        } else {
          switch (this.dTileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
          }
          break;
        }

      }

      case 4: {
        if (this.dLayout) {
          switch (this.dTileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
          }
          break;
        } else {
          switch (this.dTileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth / divider)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${(clientHeight / divider)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
          }
          break;
        }

      }

      case 5: {
        if (this.dLayout) {
          switch (this.dTileIndex) {

            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% -  ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
          }
          break;
        } else {
          switch (this.dTileIndex) {

            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
          }
          break;
        }

      }
      case 6: {
        if (this.dLayout) {
          switch (this.dTileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 3:

              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
          }
          break;
        } else {
          switch (this.dTileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${(clientHeight / divider)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 3:

              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
          }
          break;
        }
      }
      case 7: {
        if (this.dLayout) {
          switch (this.dTileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 3)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 3)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 3)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% -  ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 6:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% -  ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + (gutter * 3)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
          }
          break;
        } else {
          switch (this.dTileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${(clientHeight / divider)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 3:

              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 6:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
          }
          break;
        }

      }
      case 8: {
        if (this.dLayout) {
          switch (this.dTileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 3)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 3)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 3)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% -  ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 6:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + (gutter * 3)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 7:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + (gutter * 3)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;

          }
          break;
        } else {
          switch (this.dTileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${(clientHeight * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${(clientHeight / divider)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 6:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 5);
              break;
            case 7:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 5);
              break;

          }
          break;
        }

      }
      case 9: {
        if (this.dLayout) {
          switch (this.dTileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 3)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 3)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 3)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% -  ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 6:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + (gutter * 3)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 7:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% -  ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + (gutter * 3)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 8:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + (gutter * 3)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
          }
          break;
        } else {
          switch (this.dTileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${(clientHeight * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${(clientHeight / divider)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 6:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 5);
              break;
            case 7:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 5);
              break;
            case 8:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${(clientHeight * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 6);
              break;
          }
          break;
        }
      }
    }

  }

  private _updateElementSize() {
    const nativeElement = this._hostElement.nativeElement;

    this._renderer.setStyle(nativeElement, 'width', `18em`);
    this._renderer.setStyle(nativeElement, 'height', `10em`);

  }

}
