import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CourseFacadeService} from "@core/facades/course.facade.service";

@Component({
  selector: 'sc-volume-control',
  templateUrl: './volume-control.component.html',
  styleUrls: ['./volume-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VolumeControlComponent implements OnInit {

  volumeLevel: number = 50;
  currentVolumeLevel: number;

  constructor(private _courseFacade: CourseFacadeService) {
  }

  ngOnInit(): void {
  }

  setVolume(volume: number) {
    this.currentVolumeLevel = this.volumeLevel;
    this.volumeLevel = volume;
    this._courseFacade.changeVolume(volume)
  }
}
