@if (mediaSlide().data; as mediaData) {
  <sc-video-player
    [videoSource]="{sources: [{src: mediaData.fileUrl,type: 'video/mp4'}], subtitleUrl:mediaData?.misc?.subtitleUrl}"
    [volume]="volume$ | async"
    [unlockedTime]="mediaProgress().currentTime"
    [subtitle]="subtitle$ | async"
    (currentTimeChange)="onVideoTimeUpdate($event)"
    (videoEnd)="onVideoEnd()"
    (playing)="onVideoPlay($event)"/>
} @else {
  <div class="video-info">
    <span class="info-label">{{ mediaSlide().subCategory }} has not been selected</span>
  </div>
}
