import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sc-slide-completion-title',
  templateUrl: './slide-completion-title.component.html',
  styleUrls: ['./slide-completion-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideCompletionTitleComponent implements OnInit {

  @Input() title: string | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
