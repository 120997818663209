<ng-container *ngIf="!isBack else backIcon">

  <ng-container *ngIf="!isSlideUnlocked; else nextIcon">
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="slideTypes.QUESTION">
        <sc-question-icon></sc-question-icon>
      </ng-container>
      <ng-container *ngSwitchCase="slideTypes?.CHECKBOX">
        <sc-question-icon></sc-question-icon>
      </ng-container>
      <ng-container *ngSwitchCase="slideTypes?.BACKGROUND_VIDEO">
        <sc-question-icon></sc-question-icon>
      </ng-container>
      <ng-container *ngSwitchCase="slideTypes?.HOTSPOT">
        <sc-question-icon></sc-question-icon>
      </ng-container>
      <ng-container *ngSwitchCase="slideTypes?.VIDEO">
        <sc-video-timer></sc-video-timer>
      </ng-container>
      <ng-container *ngSwitchCase="slideTypes?.IMAGE">
        <sc-question-icon></sc-question-icon>
      </ng-container>
      <ng-container *ngSwitchCase="slideTypes?.LAYOUT">
        <sc-question-icon></sc-question-icon>
      </ng-container>
      <ng-container *ngSwitchCase="slideTypes?.EXPLAINER">
        <sc-video-timer></sc-video-timer>
      </ng-container>
      <ng-container *ngSwitchCase="slideTypes?.END">
        <sc-end-icon></sc-end-icon>
      </ng-container>
      <ng-container *ngSwitchCase="slideTypes.LEVEL">
        <sc-question-icon></sc-question-icon>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #nextIcon>
    <sc-next-icon></sc-next-icon>
  </ng-template>
</ng-container>

<ng-template #backIcon>
  <sc-back-icon></sc-back-icon>
</ng-template>


