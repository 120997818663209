<ng-container (window:keydown)="navigate($event)">

  <sc-tile *ngFor="let tileDataUnit of tileDataUnits; let i = index"
           [unlockTilesSequentially]="level.unlockTilesSequentially"
           [class.locked]="level.unlockTilesSequentially && !tileDataUnit.slideProgress.isSlideUnlocked"
           [class.completed]="tileDataUnit.slideProgress.slideCompleted"
           (click)="onSelectTile(tileDataUnit.slideProgress)"
           tileDecorator
           [dTileCount]="tileDataUnits.length"
           [dLayout]="level.gridLayout"
           [dTileIndex]="i"
           [tileData]="tileDataUnit"/>
  <div class="background" *ngIf="level.background" [style.background-image]="'url(' + level.background + ')'">
    <ng-lottie *ngIf="level.backgroundType == 'application/json'" [options]="{path: level.background}" width="100%" height="100%"/>
  </div>
  <div class="placeholder" *ngIf="level.info">
    <ng-lottie [options]="{path: 'assets/lottie/arrow-up.json'}"/>
    {{level.info}}
  </div>
</ng-container>

