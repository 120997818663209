import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sc-volume-icon',
  templateUrl: './volume-icon.component.html',
  styleUrls: ['./volume-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VolumeIconComponent implements OnInit {

  @Input() volume: number;

  constructor() { }

  ngOnInit(): void {
  }

}
