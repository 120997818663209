import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AnimatedBackgroundComponent} from "./animated-background.component";
import { VideoBackgroundComponent } from './background/video-background/video-background.component';
import { ImageGifBackgroundComponent } from './common/image-gif-background/image-gif-background.component';
import { BackgroundComponent } from './background/background.component';
import { ForegroundComponent } from './foreground/foreground.component';
import { LottieBackgroundComponent } from './common/lottie-background/lottie-background.component';
import { LottieComponent } from 'ngx-lottie';

@NgModule({
  declarations: [
    AnimatedBackgroundComponent,
    VideoBackgroundComponent,
    ImageGifBackgroundComponent,
    BackgroundComponent,
    ForegroundComponent,
    LottieBackgroundComponent,
  ],
  exports: [
    AnimatedBackgroundComponent,
    BackgroundComponent,
    ForegroundComponent,
  ],
  imports: [
    CommonModule,
    LottieComponent
  ]
})
export class AnimatedBackgroundModule { }
