import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Checkbox, Slide} from "@models/course.model";
import {CheckboxProgress, SlideProgress} from '@models/course-progress-data-types.model';

@Component({
  selector: 'sc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent {

  checkbox: Checkbox;
  checkboxProgress: CheckboxProgress;
  selectedComponent: number = 0;
  component = [1, 2];

  @Input() set checkboxSlide(checkbox: Slide | null) {
    this.checkbox = checkbox as Checkbox;
  }

  @Input() set checkboxProgressData(progressData: SlideProgress | null) {
    this.checkboxProgress = {...progressData} as CheckboxProgress;
  }

  @Output() saveProgress: EventEmitter<SlideProgress> = new EventEmitter<SlideProgress>();

  /**
   *Check Condition
   */
  checkCondition(value: boolean) {
    this.checkboxProgress.checked = value;
    this.checkboxProgress.isSlideUnlocked = value;
    this.checkboxProgress.slideCompleted = value;
    this.saveProgress.emit(this.checkboxProgress);
  }

  visitUrl(url: string) {
    if (url) {
      window.open(url, "_blank");
    }
  }

  navigate(event: any) {
    const componentCount = this.component.length;

    switch (event.code) {
      case 'ArrowDown':
      case 'Tab':
        this.selectedComponent = (this.selectedComponent % componentCount) + 1;
        break;
      case 'ArrowUp':
        this.selectedComponent = (this.selectedComponent - 1 + componentCount) % componentCount;
        if (this.selectedComponent === 0) {
          this.selectedComponent = componentCount;
        }
        break;
      case 'Enter':
      case 'Space':
        switch (this.selectedComponent) {
          case 1:
            this.visitUrl(this.checkbox.linkUrl);
            break;
          case 2:
            this.checkCondition(true);
            break;
        }
        break;
    }
  }
}
