import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sc-fullscreen-control',
  templateUrl: './fullscreen-control.component.html',
  styleUrls: ['./fullscreen-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullscreenControlComponent implements OnInit {

  @Input() isFullscreen: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
