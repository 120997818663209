import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LayoutFacadeService } from '@core/layout/layout.facade.service';
import { Observable } from 'rxjs';
import { Course } from '@models/course.model';

@Component({
  selector: 'sc-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent {

  course$: Observable<Course>;

  constructor(private _layoutFacade: LayoutFacadeService) {
    this.course$ = this._layoutFacade.course$
  }
}
