import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExplainerComponent } from './explainer.component';
import { VideoPlayerModule } from '../../../common/video-player/video-player.module';



@NgModule({
  declarations: [
    ExplainerComponent
  ],
    imports: [
        CommonModule,
        VideoPlayerModule
    ],
  exports: [
    ExplainerComponent
  ]
})
export class ExplainerModule { }
