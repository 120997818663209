import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sc-player-icon',
  templateUrl: './player-icon.component.html',
  styleUrls: ['./player-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayerIconComponent implements OnInit {
  @Input() playingStatus: boolean | null;

  constructor() {
  }

  ngOnInit(): void {
  }

}
