import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sc-image-gif-background',
  templateUrl: './image-gif-background.component.html',
  styleUrls: ['./image-gif-background.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageGifBackgroundComponent implements OnInit {

  @Input() src: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
