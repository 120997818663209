<div class="graphic">
  @if (titleSlide().graphic) {
    @if (titleSlide().graphic.fileType === 'video') {
      <video class="video-js" #videoElement></video>
    } @else {
      <div class="image-container" [style.background-image]="'url(' + titleSlide().graphic.assetUrl + ')'"></div>
    }
  }
</div>
<div class="text">
  <div class="title">{{ titleSlide().title }}</div>
  <div class="sub-title">{{ titleSlide().subTitle }}</div>
  <div class="logo">
    <img [src]="titleSlide().logoUrl" alt="">
  </div>
</div>
