import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AnimationType, BackgroundOption} from "@models/course.model";

@Component({
  selector: 'sc-foreground',
  templateUrl: './foreground.component.html',
  styleUrls: ['./foreground.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForegroundComponent implements OnInit {

  @Input() foreground: BackgroundOption;
  animationType = AnimationType

  constructor() { }

  ngOnInit(): void {
  }

}
