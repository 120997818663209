import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'sc-background-image',
  templateUrl: './background-image.component.html',
  styleUrls: ['./background-image.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('void', style({opacity: 0})),
      state('*', style({opacity: 1})),
      transition(':enter, :leave', [animate('0.3s')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackgroundImageComponent {
  loading = false;
  url: string;

  @Input() set imageUrl(imageUrl: string | undefined) {
    if (imageUrl) {
      this.url = imageUrl;
      this.loading = true;
    }

  }

  @Input() fill: boolean | undefined

  constructor(private _cdr: ChangeDetectorRef) {
  }

  onImageLoad() {
    setTimeout(() => {
      this.loading = false;
      this._cdr.markForCheck();
    }, 500);
  }
}
