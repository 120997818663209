import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AnimationOptions} from "ngx-lottie";

@Component({
  selector: 'sc-lottie-background',
  templateUrl: './lottie-background.component.html',
  styleUrls: ['./lottie-background.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LottieBackgroundComponent implements OnInit {

  @Input() src: string;
  @Input() type: string;

  options: AnimationOptions;

  constructor() {
  }

  ngOnInit(): void {
    this.options = {
      path: this.src,
    }
  }

}
