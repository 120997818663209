import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Layout} from "@models/course.model";

@Component({
  selector: 'sc-text-top',
  templateUrl: './text-top.component.html',
  styleUrls: ['./text-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextTopComponent {
  @Input() layout: Layout;
}
