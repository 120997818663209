<ng-container *ngIf="videoData; else noVideo">
  <sc-video-player [videoSource]="videoData"
                   [subtitle]="subtitle$ | async"
                   [playFrom]="videoProgress.isSlideUnlocked ? 0 : videoProgress.currentTime"
                   [autoplay] = "!videoProgress.isSlideUnlocked"
                   [volume]="volume$ | async"
                   [unlockedTime]="videoProgress.currentTime"
                   (currentTimeChange)="onVideoTimeUpdate($event)"
                   (videoEnd)="onVideoEnd()"
                   (playing)="onVideoPlay($event)">
  </sc-video-player>
</ng-container>

<ng-template #noVideo>
  <div class="no-video-selected">
    <span class="info-label">Video File not Selected</span>
  </div>
</ng-template>
