import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AnimationType, BackgroundOption} from "@models/course.model";

@Component({
  selector: 'sc-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackgroundComponent implements OnInit {

  @Input() background: BackgroundOption;
  animationType = AnimationType

  constructor() {
  }

  ngOnInit(): void {
  }

}
