import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LanguageComponent } from './language.component';
import { LanguageTitleComponent } from './language-title/language-title.component';
import { LanguageLinkItemComponent } from './language-link-item/language-link-item.component';


@NgModule({
  declarations: [
    LanguageComponent,
    LanguageTitleComponent,
    LanguageLinkItemComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LanguageComponent
  ],
  providers: [
  ]
})
export class LanguageModule { }
