import {
  ChangeDetectionStrategy,
  Component,
  ElementRef, inject,
  input,
  InputSignal, output, signal,
  ViewChild, WritableSignal
} from '@angular/core';
import {PointerIconComponent} from "./pointer-icon/pointer-icon.component";
import {TooltipModule} from "primeng/tooltip";
import {LottieComponent} from "ngx-lottie";
import {FileType, HotspotSlide, ImageSize, PointerSlide, PointerType} from "@models/slide.model";
import {GroupProgress, SlideDataUnit, SlideProgress} from "@models/course-progress-data-types.model";
import {CourseFacadeService} from "@core/facades/course.facade.service";
import {DialogModule} from "primeng/dialog";

@Component({
  selector: 'sg-hotspot-slide',
  standalone: true,
  imports: [
    PointerIconComponent,
    TooltipModule,
    LottieComponent,
    DialogModule
  ],
  templateUrl: './hotspot-slide.component.html',
  styleUrl: './hotspot-slide.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotspotSlideComponent {

  @ViewChild('container') container: ElementRef;

  hotspotSlide: InputSignal<HotspotSlide> = input.required({
    transform: (hotspotSlide) => {
      this.contentDataUnits = JSON.parse(JSON.stringify(this._courseFacade.selectGroupDataUnitList<PointerSlide>(hotspotSlide.id) as SlideDataUnit<any>[]));
      const contents = Object.keys(hotspotSlide.content).map(key => ({...hotspotSlide.content[key]}));
      this.pointers.set(contents);
      return hotspotSlide
    }
  });

  pointers: WritableSignal<PointerSlide[]> = signal([]);
  hotspotProgress: InputSignal<GroupProgress> = input.required();
  selectedPointer: PointerSlide

  contentDataUnits: SlideDataUnit<PointerSlide>[];
  selectedPointerIndex: number;
  saveProgress = output<GroupProgress | SlideProgress>();
  visible: boolean = false;

  private _courseFacade = inject(CourseFacadeService);


  setSelectedPoint(i: number, pointer: PointerSlide) {
    this.selectedPointerIndex = i;
    this.selectedPointer = pointer;

    if (pointer.pointerType === PointerType.NESTED) {
      this._courseFacade.selectTile(pointer.id)
      return
    } else {
      this.visible = true
      if(!this.contentDataUnits[i].slideProgress.slideCompleted){
        this.contentDataUnits[i].slideProgress.slideCompleted = true
        this.contentDataUnits[i].slideProgress.isSlideUnlocked = true
        this.saveProgress.emit(this.contentDataUnits[i].slideProgress);
      }
    }
  }

  protected readonly ImageSize = ImageSize;
  protected readonly FileType = FileType;
}
