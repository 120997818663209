import {
  Component,
  Input,
} from '@angular/core';
import { CourseSlideType } from '@models/course.model';

@Component({
  selector: 'sc-navigation-control',
  templateUrl: './navigation-control.component.html',
  styleUrls: ['./navigation-control.component.scss']
})
export class  NavigationControlComponent {

  @Input() isBack?: boolean;
  @Input() type: CourseSlideType;
  @Input() isSlideUnlocked: boolean;

  slideTypes = CourseSlideType;
}
