import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CourseProgressInfoComponent } from './course-progress-info.component';
import { CourseTitleComponent } from './course-title/course-title.component';
import { CourseProgressBarComponent } from './course-progress-bar/course-progress-bar.component';
import { CourseProgressComponent } from './course-progress/course-progress.component';
import { TimeRemainingPipePipe } from './course-progress/time-remaining.pipe';
import { PercentagePipe } from './course-progress/percentage.pipe';


@NgModule({
  declarations: [
    CourseProgressInfoComponent,
    CourseTitleComponent,
    CourseProgressBarComponent,
    CourseProgressComponent,
    TimeRemainingPipePipe,
    PercentagePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CourseProgressInfoComponent
  ]
})
export class CourseProgressInfoModule {
}
