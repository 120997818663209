@if (!isBack) {
  @if (subCategory == LayoutSubCategory.END) {
    <sc-end-icon/>
  } @else {
    <ng-container *ngIf="!isSlideUnlocked; else nextIcon">
      <ng-container [ngSwitch]="category">
        <ng-container *ngSwitchCase="slideCategory.QUESTION">
          <sc-not-completed-icon/>
        </ng-container>
        <ng-container *ngSwitchCase="slideCategory?.CHECKBOX">
          <sc-not-completed-icon/>
        </ng-container>
        <ng-container *ngSwitchCase="slideCategory?.IMAGE">
          <sc-not-completed-icon/>
        </ng-container>
        <ng-container *ngSwitchCase="slideCategory?.LAYOUT">
          <sc-not-completed-icon/>
        </ng-container>
        <ng-container *ngSwitchCase="slideCategory?.MEDIA">
          <sc-video-timer/>
        </ng-container>
        <ng-container *ngSwitchCase="slideCategory.GROUP">
          <sc-not-completed-icon/>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #nextIcon>
      <sc-next-icon/>
    </ng-template>
  }
} @else {
  <sc-back-icon/>
}







