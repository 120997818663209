import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {
  courseCompletionTimer,
  selectActiveSlideData,
  selectCourse,
  selectCourseProgress,
  selectLanguage,
  selectPlayerColor
} from '@root-store/course/course.selectors';
import {
  completeCourse,
  saveCourseProgressData,
  setActiveLinkMap,
  showLanguageSelection,
  updateSlideProgressData
} from '@root-store/course/course.actions';
import {
  Course,
  CourseConfig,
  CourseSlideType, Hotspot,
  HotspotPointer,
  Level,
  PointerType,
  Slide,
  Slides,
  Tile,
} from '@models/course.model';
import {ProgressDataFactory, SlideDataUnit, SlideProgress} from '@models/course-progress-data-types.model';
import {auditTime, filter, take, tap} from 'rxjs/operators';
import {NavigationFacadeService} from '@core/facades/navigation.facade.service';
import {ScriptBlock} from '@models/clip-block.model';
import {CourseProgressFacadeService} from '@core/facades/course-progress.facade.service';
import {DialogService} from 'primeng/dynamicdialog';
import {ContentComponent} from '@core/popups/content/content.component';

@Injectable({
  providedIn: 'root'
})
export class CourseFacadeService {

  course$: Observable<Course>;
  slideDataUnit$: Observable<SlideDataUnit<Slide>>;
  currentVolume$ = new BehaviorSubject(50);
  toggleSubtitle$ = new BehaviorSubject(true);
  courseCompletionTimer$: Observable<{ totalTime: number, completedTime: number }>;
  canGoBack$: Observable<boolean>;
  selectedLanguage$: Observable<string>;
  courseConfig: CourseConfig;

  flattenSlides: Slides = {};
  slideList: Slide[] = [];
  currentAsi: string = 'title';

  private _currentSlideProgressData: SlideProgress;

  // Injectors
  private _store = inject(Store);
  private _navigationFacade = inject(NavigationFacadeService);
  private _courseProgressFacade = inject(CourseProgressFacadeService);
  private _dialogService = inject(DialogService)


  constructor() {

    this.canGoBack$ = this._navigationFacade.canGoBack$;

    /********************************/

    this.course$ = this._store.select(selectCourse).pipe(
      filter((course): course is NonNullable<typeof course> => !!course),
      tap(course => this.courseConfig = course.config as CourseConfig)
    ) as Observable<Course>;

    this.slideDataUnit$ = this._store.select(selectActiveSlideData).pipe(
      filter((slide): slide is NonNullable<typeof slide> => !!slide),
      tap(slideData => this._currentSlideProgressData = (slideData as any).progressData)
    ) as Observable<SlideDataUnit<Slide>>;

    this.courseCompletionTimer$ = this._store.select(courseCompletionTimer);
    this.selectedLanguage$ = this._store.select(selectLanguage);


    /**
     * set state color
     */
    this._store.select(selectPlayerColor).pipe(
      filter(color => !!color),
      take(1)
    ).subscribe(
      color => this.setPrimaryColor(color)
    );

    /**
     * call to save data
     */
    this._store.select(selectCourseProgress).pipe(
      auditTime(3000),
    ).subscribe(progressData => {
      this._store.dispatch(saveCourseProgressData({progressData}))
    });
  }

  /**
   * Save Slide Progress Data
   * @param slideProgress
   */
  saveSlideProgressData(slideProgress: SlideProgress) {
    this._currentSlideProgressData = slideProgress;
    this._store.dispatch(updateSlideProgressData({slideProgress}));
  }

  /**
   * navigate next
   */
  navigateToNextSlide(dataUnit?: string) {
    const nextSlide = this._navigationFacade.getNextSlide(dataUnit);

    if (nextSlide.id) {
      this._store.dispatch(setActiveLinkMap({
        asi: nextSlide.id,
        cpi: nextSlide.index
      }))
    }

  }

  /**
   * navigate back
   */
  navigateToPreviousSlide() {
    const previousSlide = this._navigationFacade.getPreviousSlide();
    let previousSlideId = previousSlide.id;
    if (previousSlide.type == CourseSlideType.TITLE) {
      previousSlideId = CourseSlideType.TITLE;
    }

    this._store.dispatch(setActiveLinkMap({
      asi: previousSlideId,
      cpi: previousSlide.index
    }))
  }

  canNavigate() {

  }

  /**
   * change video volume
   * @param volume
   */
  changeVolume(volume: number) {
    this.currentVolume$.next(volume)
  }

  /**
   * change video volume
   * @param subtitle
   */
  toggleSubtitle(subtitle: boolean) {
    this.toggleSubtitle$.next(subtitle)
  }

  /**
   * Select Language
   */
  selectLanguage() {
    this._store.dispatch(showLanguageSelection())
  }

  /**
   * Complete Course
   */
  completeCourse() {
    this._store.dispatch(completeCourse())
  }


  /**
   * set course color
   * @param color
   */
  setPrimaryColor(color?: string) {
    if (color) {
      document.documentElement.style.setProperty('--sg-primary-color', color);
      document.documentElement.style.setProperty('--sg-primary-light-color', color + 'B3');
    }
  }

  processSlides(slides: Slides) {
    this._createSlideList(slides);
    this.slideList = this._processSlideIndex(this.slideList);
    this.currentAsi = this.slideList[0].id ? this.slideList[0].id : CourseSlideType.TITLE;
    this.flattenSlides = {}

    this.slideList.forEach((slide, index) => {

      let slideCopy = {...slide} as Slide;
      slideCopy.index = index;
      if (slideCopy.type === CourseSlideType.LEVEL) {
      }
      if (slideCopy.type === CourseSlideType.TILE && slideCopy.content) {
        // slideCopy.content = {}
      }
      if (slideCopy.type === CourseSlideType.EXPLAINER && slideCopy.explainerData) {
        // todo: create a factory method to create slide
        slideCopy = {
          type: CourseSlideType.VIDEO,
          index: slideCopy.index,
          id: slideCopy.id,
          link: slideCopy.link,
          videoData: {
            url: slideCopy.explainerData.fileUrl,
            subtitleUrl: slideCopy.explainerData.subtitleUrl,
            duration: slideCopy.explainerData.duration ?
              slideCopy.explainerData.duration :
              this._calculateExplainerDuration(slideCopy.explainerData.scriptBlocks),
            thumbUrl: slideCopy.explainerData.thumbUrl as string
          },
          title: slideCopy.title,
          lastItemInLink: slideCopy.lastItemInLink,
          firstItemInLink: slideCopy.firstItemInLink
        };
      }
      if (slideCopy.type === CourseSlideType.END) {
        slideCopy.link = [CourseSlideType.END]
      }
      this.flattenSlides[slideCopy.id || CourseSlideType.TITLE] = slideCopy;
    });
    this._navigationFacade.initNavigation(this.slideList);
    return this.flattenSlides;
  }

  selectGroupDataUnitList<T = Tile | HotspotPointer>(slideId: string) {
    let groupChildIds: string[] = [];

    if (this.flattenSlides[slideId].type === CourseSlideType.LEVEL) {
      groupChildIds = Object.keys((this.flattenSlides[slideId] as Level).tiles)
    } else if (this.flattenSlides[slideId].type === CourseSlideType.HOTSPOT) {
      groupChildIds = Object.keys((this.flattenSlides[slideId] as Hotspot).pointers)
    }

    const groupDataUnits: SlideDataUnit<Tile | HotspotPointer>[] = ProgressDataFactory
      .createGroupChildDataUnitList(
        groupChildIds,
        this.flattenSlides,
        this._courseProgressFacade.courseProgress.slideData
      );
    return groupDataUnits;
  }

  selectTile(tileId: string) {
    if ((this.flattenSlides[tileId] as Tile).content) {
      this.navigateToNextSlide(tileId);
    }
  }

  selectPointer({slide: pointer, slideProgress}: SlideDataUnit<HotspotPointer>) {
    if (pointer.pointerType === PointerType.NESTED) {
      this.navigateToNextSlide(pointer.id);
    } else if (pointer.pointerType === PointerType.POPUP) {
      const dialogRef = this._dialogService.open(ContentComponent, {
        closable: true,
        maximizable: true,
        header: pointer.name,
        closeOnEscape: false,
        width: '45em',
        data: {
          popupContent: pointer.popupText
        }
      });

      const sub = dialogRef.onClose.subscribe(_ => {
        const slideProgressCopy: SlideProgress = JSON.parse(JSON.stringify(slideProgress));
        slideProgressCopy.isSlideUnlocked = true;
        slideProgressCopy.slideCompleted = true;
        this.saveSlideProgressData(slideProgressCopy)
        sub.unsubscribe();
      })
    }
  }

  // todo: remove and get duration from api
  private _calculateExplainerDuration(scripts: ScriptBlock[]): number {
    return scripts
      .map(script => script.seconds)
      .reduce((a, b) => a + b, 0)
  }

  private _createSlideList(slides: Slides) {
    const slideList = this._getSlideAsList(JSON.parse(JSON.stringify(slides)));

    slideList.forEach((slide, index) => {
      slide.lastItemInLink = (slideList.length === (index + 1));
      this.slideList.push(slide)
      // Root Level ---------------------------->
      if (slide.type === CourseSlideType.LEVEL) {
        // Tiles --------------------->
        const tilesList = this._getSlideAsList(slide.tiles || {});
        tilesList.forEach((tile, tileIndex) => {
          tile.firstItemInLink = (tileIndex === 0);
          tile.lastItemInLink = tilesList.length === (tileIndex + 1);
          if ((tile as Tile).content && Object.values((tile as Tile).content).length) {
            this.slideList.push(tile);
            // Tile Content --------------------------->
            this._createSlideList((tile as Tile).content);
          }
        })
        // Tiles <----------------------------
      }

      // HOTSPOT and Pointers
      if (slide.type === CourseSlideType.HOTSPOT) {
        const pointerList = this._getSlideAsList(slide.pointers || {});

        pointerList.forEach((pointer, pointerIndex) => {
          pointer.firstItemInLink = (pointerIndex === 0);
          pointer.lastItemInLink = pointerList.length === (pointerIndex + 1);
          if ((pointer as HotspotPointer).pointerType === PointerType.NESTED) {
            if ((pointer as HotspotPointer).content && Object.values((pointer as Tile).content).length) {
              this.slideList.push(pointer);
              // Pointer Content --------------------------->
              this._createSlideList((pointer as HotspotPointer).content);
            }
          } else if ((pointer as HotspotPointer).pointerType === PointerType.POPUP) {
            this.slideList.push(pointer);
          }
        })
      }
    })
  }

  private _getSlideAsList(slides: Slides): Slide[] {
    return Object
      .values(slides || {})
      .sort((a, b) => a.index - b.index);
  }

  private _processSlideIndex(slideNavList: Slide[]) {
    return slideNavList.map((slide, index) => {
      const slideCopy = {...slide}
      slideCopy.index = index;
      return slideCopy;
    })
  }
}

