import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Image} from "@models/course.model";

@Component({
  selector: 'sc-image-container',
  templateUrl: './image-container.component.html',
  styleUrls: ['./image-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageContainerComponent implements OnInit {

  @Input('image') image: Image | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
