import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CheckboxLinkComponent} from './checkbox-link/checkbox-link.component';
import {CheckboxConditionComponent} from './checkbox-condition/checkbox-condition.component';
import {ImageContainerComponent} from './image-container/image-container.component';
import {FormsModule} from "@angular/forms";
import { LottieComponent } from 'ngx-lottie';


@NgModule({
  declarations: [
    CheckboxLinkComponent,
    CheckboxConditionComponent,
    ImageContainerComponent
  ],
  exports: [
    ImageContainerComponent,
    CheckboxLinkComponent,
    CheckboxConditionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LottieComponent
  ]
})
export class CheckboxModule {
}
