import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AnimationOptions} from "ngx-lottie";
import {End, Slide} from "@models/course.model";
import {CourseFacadeService} from "@core/facades/course.facade.service";
import {Observable} from "rxjs";
import { EndSlideProgress } from '@models/course-progress-data-types.model';

@Component({
  selector: 'sc-slide-completion',
  templateUrl: './slide-completion.component.html',
  styleUrls: ['./slide-completion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideCompletionComponent implements OnInit {

  end: End;
  endSlideProgressData: EndSlideProgress;

  @Input() set endSlide(end: Slide | null) {
    this.end = end as End;
  }

  @Input() set endProgressData(progressData: EndSlideProgress | null) {
    this.endSlideProgressData = {...progressData} as EndSlideProgress;
  }

  options: AnimationOptions = {
    path: 'assets/lottie/confetti.json',
  };

  constructor(private _courseFacade: CourseFacadeService) {
  }

  ngOnInit(): void {
    this._courseFacade.completeCourse();
  }

}
