import {
  GroupCategory,
  LayoutSubCategory,
  MediaCategory,
  MediaSlide,
  Slide,
  SlideCategory,
  Slides,
  SlideType
} from '@models/slide.model';

/**
 * common interfaces and types for course progress
 */
export interface SlideProgressData {
  [slideIndex: string]: SlideProgress;
}

export type SlideProgress =
  | QuestionProgress
  | CheckboxProgress
  | GroupProgress
  | MediaProgress
  | LayoutProgress
  | HotspotProgress

export interface TimerConfig {
  run: boolean;
  duration: number;
  from: number;
}

export const localStorageKey = 'sg_course_progress'


/**
 * data types to save in scorm or other LMS api
 */

class SlideProgressBase {
  isSlideUnlocked: boolean = false;
  category: SlideCategory;
  id: string;
  duration: number = 30;
  slideCompleted = false;
  parentId?: string;

  constructor(slide: Slide) {
    this.category = slide.category;
    this.id = slide.id;
  }
}

/**
 * Question Progress Data
 */
// export class TitleProgressData extends SlideProgressBase {
//   override duration = 0;
//   override isSlideUnlocked = true;
//   override id = CourseSlideType.TITLE;
//   tileLockSequentially: boolean;
//
//   constructor(slide: Slide) {
//     super(slide)
//   }
// }


/**
 * Question Progress Data
 */
export class QuestionProgress extends SlideProgressBase {
  allowedAttempts?: number;
  attempts: number
  isSelectedAnswerCorrect: boolean = false;
  selectedAnswerIndexes: number[] = [];

  constructor(slide: Slide) {
    super(slide)
  }
}

/**
 * Hotspot Progress Data
 */
export class HotspotProgress extends SlideProgressBase {
  pRef: string[]; // pointer refs (associated pointer id list)
  override duration = 0;

  constructor(slide: Slide) {
    super(slide)
  }
}

/**
 * Pointer Progress Data
 */
export class PointerProgress extends SlideProgressBase {

  cRef: string[] = []; // content refs (associated content id list)
  override duration = 0

  constructor(slide: Slide) {
    super(slide);
  }
}

/**
 * Checkbox Progress Data
 */
export class CheckboxProgress extends SlideProgressBase {
  checked: boolean = false;

  constructor(slide: Slide) {
    super(slide)
  }
}

/**
 * Level Progress Data
 */
export class GroupProgress extends SlideProgressBase {

  subCategory: GroupCategory;
  cRef: string[]; // content refs (associated tile id list)
  override duration = 0

  // override isSlideUnlocked = true;

  constructor(slide: Slide) {
    super(slide)
  }
}

/**
 * Tile Progress Data
 */

/*export class TileProgress extends SlideProgressBase {

  cRef: string[] = []; // content refs (associated content id list)
  tileLockSequentially: boolean;
  override duration = 0

  constructor(slide: Slide) {
    super(slide);
    this.tileLockSequentially = (slide as Tile).tileLockSequentially;
  }
}*/

export class MediaProgress extends SlideProgressBase {
  currentTime: number = 0;
  playing: boolean = false;

  constructor(mediaSlide: MediaSlide) {
    super(mediaSlide);
    if (mediaSlide.data) {
      this.duration = mediaSlide.data?.misc!.duration;
    } else {
      this.duration = 0;
      this.isSlideUnlocked = true;
    }

  }
}

/**
 * Video Progress Data
 */

/*export class VideoProgress extends SlideProgressBase {
  currentTime: number = 0;
  playing: boolean = false;

  constructor(slide: Slide) {
    super(slide);
    if ((slide as Video).videoData) {
      this.duration = (slide as Video).videoData.duration;
    } else {
      this.duration = 0;
      this.isSlideUnlocked = true;
    }

  }
}*/

/**
 * Image Progress Data
 */
export class ImageProgress extends SlideProgressBase {
  checked: boolean = false;
  override isSlideUnlocked = true;

  constructor(slide: Slide) {
    super(slide);
  }
}

/**
 * Layout Progress Data
 */
export class LayoutProgress extends SlideProgressBase {
  checked: boolean = false;
  override isSlideUnlocked = true;

  constructor(slide: Slide) {
    super(slide);
  }
}

/**
 * Image Progress Data
 */
export class AnimatedBackgroundProgress extends SlideProgressBase {
  checked: boolean = false;
  override isSlideUnlocked = true;

  constructor(slide: Slide) {
    super(slide);
  }
}

/**
 * Explainer Progress Data
 */

/*export class ExplainerProgress extends SlideProgressBase implements VideoProgress {

  currentTime: number = 0;
  playing: boolean = false;

  constructor(slide: Slide) {
    super(slide);
    if ((slide as ExplainerVideo).explainerData) {
      if ((slide as ExplainerVideo).explainerData.duration) {
        this.duration = (slide as ExplainerVideo).explainerData.duration
      } else {
        (slide as ExplainerVideo).explainerData.scriptBlocks.forEach(scriptBlock => {
          this.duration += scriptBlock.seconds
        })
      }
    } else {
      this.duration = 0;
      this.isSlideUnlocked = true;
    }

  }
}*/

/**
 * End Slide Progress Data
 */
export class EndSlideProgress extends SlideProgressBase {

  // override duration = 0;

  constructor(slide: Slide) {
    super(slide)
  }
}

/**
 * Slide unit with combined progress data and slide content
 */
export class SlideDataUnit<T extends SlideType = SlideType> {
  category: SlideCategory;
  subCategory: GroupCategory | MediaCategory | LayoutSubCategory | undefined;
  slideProgress: SlideProgress;
  slide: T

  constructor(slide: T, slideProgress: SlideProgress) {
    this.slide = slide;
    this.slideProgress = slideProgress ? JSON.parse(JSON.stringify(slideProgress)) : slideProgress
    this.category = slide.category
    this.subCategory = slide.subCategory
  }
}

/**
 * factory class to create class types
 */
export class ProgressDataFactory {

  level: number = 1;

  static createSlideProgressData(slides: Slides, isUnlocked: boolean): SlideProgressData {

    const slideData: SlideProgressData = {};

    Object.values(slides || {}).forEach((rootSlide) => {

      slideData[rootSlide.id] = ProgressDataFactory
        ._createProgressDataType(
          rootSlide,
          null,
          isUnlocked
        ) as GroupProgress;

      switch (rootSlide.category) {
        case SlideCategory.GROUP:
          (slideData[rootSlide.id] as GroupProgress).cRef = Object.keys(rootSlide.content);
          break;

        /*case CourseSlideType.TILE:
          (slideData[rootSlide.id] as TileProgress).isSlideUnlocked = slides[rootSlide.id].firstItemInLink;
          (slideData[rootSlide.id] as TileProgress).cRef = Object.keys(rootSlide.content);
          break;

        case CourseSlideType.HOTSPOT:
          (slideData[rootSlide.id] as HotspotProgress).pRef = Object.keys(rootSlide.pointers);
          break;

        case CourseSlideType.POINTER:
          (slideData[rootSlide.id] as PointerProgress).isSlideUnlocked = slides[rootSlide.id].firstItemInLink;
          if((rootSlide as HotspotPointer).pointerType === PointerType.NESTED) {
            (slideData[rootSlide.id] as PointerProgress).cRef = Object.keys(rootSlide.content);
          }
          break;*/
      }

    });

    return slideData;
  }

  private static _createProgressDataType(slide: Slide, parentId: string | null, isUnlocked?: boolean): SlideProgressBase | null {

    let slideProgressData: SlideProgressBase;

    switch (slide.category) {
      case SlideCategory.LAYOUT:
        slideProgressData = new LayoutProgress(slide);
        break;

      case SlideCategory.QUESTION:
        slideProgressData = new QuestionProgress(slide);
        break;
      /*
            case CourseSlideType.HOTSPOT:
              slideProgressData = new HotspotProgress(slide);
              break;

            case CourseSlideType.POINTER:
              slideProgressData = new PointerProgress(slide);
              break;*/

      case SlideCategory.GROUP:
        slideProgressData = new GroupProgress(slide);
        break;

      case SlideCategory.MEDIA:
        slideProgressData = new MediaProgress(slide as MediaSlide);
        break;

      /*case CourseSlideType.EXPLAINER:
        slideProgressData = new ExplainerProgress(slide);
        if (isUnlocked) {
          (slideProgressData as ExplainerProgress).currentTime = slideProgressData.duration;
        }
        break;

      case CourseSlideType.VIDEO:
        slideProgressData = new VideoProgress(slide);
        if (isUnlocked) {
          (slideProgressData as VideoProgress).currentTime = slideProgressData.duration
        }
        break;

      case CourseSlideType.BACKGROUND_VIDEO:
        slideProgressData = new AnimatedBackgroundProgress(slide)
        break;

      case CourseSlideType.IMAGE:
        slideProgressData = new ImageProgress(slide);
        break

      case CourseSlideType.LAYOUT:
        slideProgressData = new LayoutProgress(slide);
        break*/

      case SlideCategory.CHECKBOX:
        slideProgressData = new CheckboxProgress(slide);
        break;

      // case CourseSlideType.END:
      //   return new EndSlideProgress(slide)

      default:
        return null
    }

    if (isUnlocked) {
      slideProgressData.isSlideUnlocked = true;
    }

    return slideProgressData;

  }

  static createSlideDataUnit(activeSlideId: string, slides: Slides, progressData: SlideProgressData): any {

    /*if (activeSlideId === CourseSlideType.TITLE) {
      return new SlideDataUnit(slides[CourseSlideType.TITLE], progressData[CourseSlideType.TITLE]);
    }*/

    return new SlideDataUnit(slides[activeSlideId], progressData[activeSlideId]);
  }

  static createGroupChildDataUnitList(childIdList: string[], slides: Slides, progressData: SlideProgressData): any {
    return childIdList
      .filter(childId => !!slides[childId])
      .map(childId => {
        return new SlideDataUnit(slides[childId], progressData[childId]);
      })
  }
}
