import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {debounceTime, tap} from 'rxjs/operators';
import {SlideDataUnit,} from '@models/course-progress-data-types.model';
import {CourseFacadeService} from '@core/facades/course.facade.service';
import {AsyncPipe, NgIf} from '@angular/common';
import {NavigationControlComponent} from './navigation-control/navigation-control.component';
import {TooltipModule} from 'primeng/tooltip';
import {CourseProgressInfoComponent} from './course-progress-info/course-progress-info.component';
import {BaseControlsComponent} from './base-controls/base-controls.component';
import {TranslationPipe} from '../../pipes/translation.pipe';
import {LayoutSubCategory} from "@models/slide.model";

@Component({
  selector: 'sc-player-controls',
  templateUrl: './player-controls.component.html',
  styleUrls: ['./player-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NavigationControlComponent, TooltipModule, CourseProgressInfoComponent, BaseControlsComponent, AsyncPipe, TranslationPipe]
})
export class PlayerControlsComponent implements OnInit {

  @Input() title: string;
  slideDataUnit$: Observable<SlideDataUnit>;
  canGoBack$: Observable<boolean>;
  selectedLanguage$: Observable<string>;

  private _canGoBack: boolean;

  constructor(private _courseFacade: CourseFacadeService) {
    this.slideDataUnit$ = _courseFacade.slideDataUnit$.pipe(
      debounceTime(10)
    );
    this.canGoBack$ = this._courseFacade.canGoBack$.pipe(
      tap(v => this._canGoBack = v)
    );
  }

  navigateToNextSlide(dataUnit: SlideDataUnit) {
    if (dataUnit.subCategory == LayoutSubCategory.END) {
      return
    }
    if (dataUnit && dataUnit.slideProgress.isSlideUnlocked) {
      this._courseFacade.navigateToNextSlide();
    }
  }

  navigateToPreviousSlide() {
    if (this._canGoBack) {
      this._courseFacade.navigateToPreviousSlide();
    }
  }

  private _canNavigate(dataUnit: SlideDataUnit) {
    if (dataUnit) {
      /*if (dataUnit?.type === CourseSlideType.LEVEL) {
        return true
      }*/

      return dataUnit.slideProgress.isSlideUnlocked
    }

    return false;
  }

  ngOnInit(): void {
    this.selectedLanguage$ = this._courseFacade.selectedLanguage$;
  }

}
