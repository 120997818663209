import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlayerControlsComponent } from './player-controls.component';
import { NavigationControlComponent } from './navigation-control/navigation-control.component';
import { BaseControlsModule } from './base-controls/base-controls.module';
import { CourseProgressInfoModule } from './course-progress-info/course-progress-info.module';
import { NextIconComponent } from './navigation-control/next-icon/next-icon.component';
import { QuestionIconComponent } from './navigation-control/question-icon/question-icon.component';
import { BackIconComponent } from './navigation-control/back-icon/back-icon.component';
import { VideoTimerComponent } from './navigation-control/video-timer/video-timer.component';
import { EndIconComponent } from './navigation-control/end-icon/end-icon.component';
import { TooltipModule } from 'primeng/tooltip';
import {TranslationPipe} from "@core/pipes/translation.pipe";
import { UnlockStatusPipe } from './unlock-status.pipe';


@NgModule({
  declarations: [
    PlayerControlsComponent,
    NavigationControlComponent,
    NextIconComponent,
    QuestionIconComponent,
    BackIconComponent,
    VideoTimerComponent,
    EndIconComponent,
    UnlockStatusPipe,
  ],
  imports: [
    CommonModule,
    BaseControlsModule,
    CourseProgressInfoModule,
    TooltipModule,
    TranslationPipe
  ],
  exports: [
    PlayerControlsComponent,
  ]
})
export class PlayerControlsModule {
}
