import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sc-language-control',
  templateUrl: './language-control.component.html',
  styleUrls: ['./language-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageControlComponent {

}
