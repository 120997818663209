import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LevelComponent } from './level.component';
import { TileComponent } from './tile/tile.component';
import { BaseComponent } from './tile/base/base.component';
import { EventsComponent } from './tile/events/events.component';
import { TileDecoratorDirective } from './tile/tile-decorator.directive';
import { AnimationComponent } from './tile/animation/animation.component';
import player from 'lottie-web';
import { LottieComponent } from 'ngx-lottie';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    LevelComponent,
    BaseComponent,
    EventsComponent,
    TileComponent,
    TileDecoratorDirective,
    AnimationComponent
  ],
  imports: [
    CommonModule,
    LottieComponent
  ],
  exports: [
    LevelComponent
  ]
})
export class LevelModule { }
