import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageComponent} from './image.component';
import {LinkDirective} from './link.directive';
import {LinkComponent} from './link/link.component';
import {BackgroundImageComponent} from './background-image/background-image.component';
import {SpinnerModule} from "../../../common/spinner/spinner.module";
import { AudioNarratorComponent } from './audio-narrator/audio-narrator.component';
import {TooltipModule} from "primeng/tooltip";
import {TranslationPipe} from "@core/pipes/translation.pipe";

@NgModule({
  declarations: [
    ImageComponent,
    LinkDirective,
    LinkComponent,
    BackgroundImageComponent,
    AudioNarratorComponent,
  ],
    exports: [
        ImageComponent,
        BackgroundImageComponent,
        AudioNarratorComponent
    ],
  imports: [
    CommonModule,
    SpinnerModule,
    TooltipModule,
    TranslationPipe
  ]
})
export class ImageModule {
}
