import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderModule } from 'primeng/slider';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '@core/core.module';

import { VideoPlayerComponent } from './video-player.component';
import { VideoControlsComponent } from './video-controls/video-controls.component';
import { PlayerIconComponent } from './video-controls/player-icon/player-icon.component';
import { PlayerTimestampComponent } from './video-controls/player-timestamp/player-timestamp.component';
import {
  PlayerCurrentTimestampComponent
} from './video-controls/player-current-timestamp/player-current-timestamp.component';
import { PlayerTimelineComponent } from './video-controls/player-timeline/player-timeline.component';


@NgModule({
  declarations: [
    VideoPlayerComponent,
    VideoControlsComponent,
    PlayerIconComponent,
    PlayerTimestampComponent,
    PlayerCurrentTimestampComponent,
    PlayerTimelineComponent
  ],
  imports: [
    CommonModule,
    SliderModule,
    FormsModule,
    CoreModule
  ],
  exports: [
    VideoPlayerComponent
  ]
})
export class VideoPlayerModule {
}
