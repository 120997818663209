import {ChangeDetectionStrategy, Component, inject, input, InputSignal, signal, WritableSignal} from '@angular/core';
import {LayoutType, LevelSlide, TileSlide} from "@models/slide.model";
import {LottieComponent} from "ngx-lottie";
import {TileSlideComponent} from "../tile-slide/tile-slide.component";
import {TileDecoratorDirective} from "./tile-decorator.directive";
import {CourseFacadeService} from "@core/facades/course.facade.service";
import {GroupProgress, SlideDataUnit} from "@models/course-progress-data-types.model";
import {JsonPipe} from "@angular/common";

@Component({
  selector: 'sg-level-slide',
  standalone: true,
  imports: [
    LottieComponent,
    TileSlideComponent,
    TileDecoratorDirective,
    JsonPipe
  ],
  templateUrl: './level-slide.component.html',
  styleUrl: './level-slide.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LevelSlideComponent {

  levelSlide: InputSignal<LevelSlide> = input.required({
    transform: (levelSlide) => {
      this.contentDataUnits = this._courseFacade.selectGroupDataUnitList<TileSlide>(levelSlide.id) as SlideDataUnit<TileSlide>[];
      const tiles = Object.keys(levelSlide.content).map(key => ({...levelSlide.content[key]}));
      this.tiles.set(tiles);
      return levelSlide
    }
  });

  levelProgress: InputSignal<GroupProgress> = input.required({
    transform: (levelProgress) => {
      return levelProgress
    }
  });


  tiles: WritableSignal<TileSlide[]> = signal([]);

  contentDataUnits: SlideDataUnit<TileSlide>[];

  private _courseFacade = inject(CourseFacadeService);


  onSelectTile(tile: TileSlide) {
    // if (this.level.unlockTilesSequentially) {
    //   if (!tileProgress.isSlideUnlocked) {
    //     return
    //   }
    // }
    this._courseFacade.selectTile(tile.id)
  }

  protected readonly LayoutType = LayoutType;
}
