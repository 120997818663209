import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CourseFacadeService } from '@core/facades/course.facade.service';
import { Observable } from 'rxjs';
import {
  SlideDataUnit,
  SlideProgress,
} from '@models/course-progress-data-types.model';

@Component({
  selector: 'sc-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideComponent {

  activeSlide$: Observable<SlideDataUnit>;

  constructor(private _courseFacade: CourseFacadeService) {
    this.activeSlide$ = _courseFacade.slideDataUnit$;
  }

  saveProgress(progress: SlideProgress) {
    this._courseFacade.saveSlideProgressData(progress);
  };

}
