<div class="condition-section" (click)="checkedCondition()">
  <div class="checkbox-icon">
    <ng-container *ngIf="checked">
      <ng-lottie height="2.8em" [options]="options"></ng-lottie>
    </ng-container>
    <ng-container *ngIf="!checked">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="12" height="12" rx="6" fill="#eee"/>
      </svg>
    </ng-container>
  </div>
  <span class="condition-text">{{conditionText}}</span>
  <ul *ngIf="points && points.length > 0" class="points">
    <li *ngFor="let point of points">{{point}}</li>
  </ul>
</div>
