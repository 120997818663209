<ng-container *ngIf="image && image.selectedImage">
  <sc-background-image [fill]="image.isFillScreen" [imageUrl]="image.selectedImage.url"></sc-background-image>
  <sc-link
    *ngFor="let link of image?.links"
    [scLink]="link"
    (click)="openLink(link.href)"
    class="link">
  </sc-link>
  <sc-audio-narrator [volume]="volume$ | async" *ngIf="image.audioUrl" [audioUrl]="image.audioUrl"></sc-audio-narrator>
</ng-container>

