import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { Tile, TileIcon } from '@models/course.model';
import { SlideDataUnit } from '@models/course-progress-data-types.model';

@Component({
  selector: 'sc-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileComponent {

  @Input() unlockTilesSequentially: boolean;
/*  @Input() titleTitle: string;
  @Input() tileLockSequentially: boolean;
  @Input() isCompleted: boolean;
  @Input() tileIcon : TileIcon | null;*/


  @Input() tileData: SlideDataUnit<Tile>;


}
