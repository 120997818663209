import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-course-progress-bar',
  templateUrl: './course-progress-bar.component.html',
  styleUrls: ['./course-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CourseProgressBarComponent implements OnInit {

  @Input() set courseCompletionTimer(vals: { totalTime: number, completedTime: number }) {
    this.courseProgress =  vals ? (100 * vals.completedTime) / vals.totalTime : 0;
  }

  courseProgress: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
