import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import {
  SlideDataUnit,
} from '@models/course-progress-data-types.model';
import {CourseFacadeService} from '@core/facades/course.facade.service';
import {CourseSlideType} from '@models/course.model';

@Component({
  selector: 'sc-player-controls',
  templateUrl: './player-controls.component.html',
  styleUrls: ['./player-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayerControlsComponent implements OnInit {

  @Input() title: string;
  slideDataUnit$: Observable<SlideDataUnit>;
  canGoBack$: Observable<boolean>;
  selectedLanguage$: Observable<string>;

  private _canGoBack: boolean;

  constructor(private _courseFacade: CourseFacadeService) {
    this.slideDataUnit$ = _courseFacade.slideDataUnit$.pipe(
      debounceTime(10)
    );
    this.canGoBack$ = this._courseFacade.canGoBack$.pipe(
      tap(v => this._canGoBack = v)
    );
  }

  navigateToNextSlide(dataUnit: SlideDataUnit) {
    if (dataUnit && dataUnit.slideProgress.isSlideUnlocked) {
      this._courseFacade.navigateToNextSlide();
    }
  }

  navigateToPreviousSlide() {
    if (this._canGoBack) {
      this._courseFacade.navigateToPreviousSlide();
    }
  }

  private _canNavigate(dataUnit: SlideDataUnit) {
    if (dataUnit) {
      /*if (dataUnit?.type === CourseSlideType.LEVEL) {
        return true
      }*/

      return dataUnit.slideProgress.isSlideUnlocked
    }

    return false;
  }

  ngOnInit(): void {
    this.selectedLanguage$ = this._courseFacade.selectedLanguage$;
  }

}
