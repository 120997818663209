<ng-container *ngIf="explainer?.explainerData">
  <div class="explainer-not-exported" *ngIf="!explainer.explainerData?.isExported">
    <p>Please export the explainer video in order to preview it correctly.</p>
<!--    <button [routerLink]="'/explainer/' + explainer.id + '/edit'" class="btn btn-primary">Edit explainer</button>-->
  </div>
</ng-container>

<ng-container *ngIf="explainer?.explainerData && explainer.explainerData.isExported; else selectVideo">
  <sc-video-player [videoSource]="explainerData"
                   [subtitle]="subtitle$ | async"
                   [playFrom]="explainerProgress.isSlideUnlocked ? 0 : explainerProgress.currentTime"
                   [autoplay] = "!explainerProgress.isSlideUnlocked"
                   [volume]="volume$ | async"
                   [unlockedTime]="explainerProgress.currentTime"
                   (currentTimeChange)="explainerTime$.next($event)"
                   (videoEnd)="onVideoEnd()"
                   (playing)="onVideoPlay($event)">
  </sc-video-player>
</ng-container>
<ng-template #selectVideo>
  <div class="video-info">
    <span class="info-label">Explainer not selected</span>
  </div>
</ng-template>
