import { inject, Injectable } from '@angular/core';
import { SlideProgressData } from '@models/course-progress-data-types.model';
import { Store } from '@ngrx/store';
import { selectCourseProgress } from '@root-store/course/course.selectors';
import { filter } from 'rxjs/operators';
import { CourseProgress } from '@models/course-progress.model';

@Injectable({
  providedIn: 'root'
})
export class CourseProgressFacadeService {

  slideProgressData: SlideProgressData;
  courseProgress: CourseProgress;

  private _store: Store = inject(Store);

  constructor() {
    this._store.select(selectCourseProgress)
      .pipe(
        filter(data => !!data)
      ).subscribe(courseProgress => this.courseProgress = courseProgress)
  }

}
