<div class="checkbox-container" (window:keydown)="navigate($event)">
  <div class="checkbox-section-wrapper">
    <sc-image-container [image]="checkbox.selectedImage"></sc-image-container>

    <sc-checkbox-link [ngClass]="selectedComponent == 1 ? 'select-link':''"
                      (click)="visitUrl(checkbox.linkUrl)"
                      [link]="checkbox.linkText"></sc-checkbox-link>

    <sc-checkbox-condition [ngClass]="selectedComponent == 2 ? 'select-checkbox':''"
                           [points]="checkbox.conditionPoints"
                           [conditionText]="checkbox.conditionText"
                           [checked]="checkboxProgress.checked"
                           (checkedEvent)="checkCondition($event)">
    </sc-checkbox-condition>
  </div>
</div>
