import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import videojs from "video.js";
import {BackgroundOption} from "@models/course.model";
import Player from 'video.js/dist/types/player';

@Component({
  selector: 'sc-video-background',
  templateUrl: './video-background.component.html',
  styleUrls: ['./video-background.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoBackgroundComponent {

  player: Player;
  @ViewChild('videoElementRef', {static: true}) videoElementRef: ElementRef;

  @Input() set src(src: BackgroundOption) {
    this._initPlayer(src);
  }

  private _initPlayer(src: BackgroundOption) {
    if (src) {
      this.player = videojs(this.videoElementRef.nativeElement, {
        preload: 'auto',
        controlBar: false,
        userActions: {doubleClick: false, click: false},
        autoplay: true,
        poster: src?.thumbUrl ? src?.thumbUrl : undefined,
        loop: true,
        muted: true,
        bigPlayButton: false
      });
      this.player.ready(() => {
        this._updateVideoUrl(src.src);
      });
    }
  }

  private _updateVideoUrl(videoSrc: string) {
    if (this.player) {
      this.player.src({
        src: videoSrc
      });
    }
  }

}
