import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Answer} from '@models/course.model';

@Component({
  selector: 'app-answer-section',
  templateUrl: './answer-section.component.html',
  styleUrls: ['./answer-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnswerSectionComponent implements OnInit {

  @Input('answerObject') answer: Answer;
  @Input('index') index: any | undefined;
  @Input('correctAnswerIndexes') correctAnswerIndexes: number[];
  @Input('selectedIndex') selectedIndex: number;

  @Input() set savedAnswerIndexes(savedAnswerIndexes: number[]) {
    this.answered = savedAnswerIndexes.some(i => this.index === i);
  };

  answerStatus: boolean;
  answered: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.answerStatus = this.correctAnswerIndexes.some(i => this.index === i);
  }

}
