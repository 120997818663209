@for (tile of (tiles()); track tile; let index = $index; ) {
  <sg-tile-slide tileDecorator
                 [tileData]="{index: index, count: $count, isGrid: levelSlide().layoutType === LayoutType.GRID}"
                 [class.locked]="tiles()[index].tileLockSequentially"
                 [isUnlocked]="contentDataUnits[index].slideProgress.slideCompleted"
                 [tileLockSequentially]="tiles()[index].tileLockSequentially"
                 (click)="onSelectTile(tile)"
                 [tileIndex]="index"
                 [tileSlide]="tile"/>
}

@if (levelSlide().graphic; as graphic) {
  <div class="background" [style.background-image]="'url(' + graphic.fileUrl + ')'">
    @if (graphic.fileType == 'application/json') {
      <ng-lottie [options]="{path: graphic.fileUrl}"
                 width="100%"
                 height="100%"/>
    }
  </div>
}

@if (levelSlide().enableInfoText) {
  <div class="placeholder">
    <ng-lottie [options]="{path: 'assets/lottie/arrow-up.json'}"/>
    {{ levelSlide().infoText }}
  </div>
}

