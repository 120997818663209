import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-course-title',
  templateUrl: './course-title.component.html',
  styleUrls: ['./course-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CourseTitleComponent implements OnInit {

  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
