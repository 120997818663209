import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {TextContentType} from "@models/course.model";

@Directive({
  selector: '[scTextContentType]'
})
export class TextContentTypeDirective {

  @Input('scTextContentType') set fontSize(value: TextContentType) {
    switch (value) {
      case TextContentType.NORMAL:
        this.renderer.setStyle(this.el.nativeElement, 'font-size', '1em');
        break;
      case TextContentType.HEADING1:
        this.renderer.setStyle(this.el.nativeElement, 'font-size', '3em');
        this.renderer.setStyle(this.el.nativeElement, 'font-weight', '500');
        break;
      case TextContentType.HEADING2:
        this.renderer.setStyle(this.el.nativeElement, 'font-size', '2.5em');
        this.renderer.setStyle(this.el.nativeElement, 'font-weight', '400');
        break;
      case TextContentType.HEADING3:
        this.renderer.setStyle(this.el.nativeElement, 'font-size', '2em');
        this.renderer.setStyle(this.el.nativeElement, 'font-weight', '400');
        break;
      case TextContentType.HEADING4:
        this.renderer.setStyle(this.el.nativeElement, 'font-size', '1.5em');
        break;
      case TextContentType.HEADING5:
        this.renderer.setStyle(this.el.nativeElement, 'font-size', '1.2em');
        break;
    }
    this.renderer.setStyle(this.el.nativeElement, 'word-break', 'break-word');
    this.renderer.setStyle(this.el.nativeElement, 'font-family', '"Roboto", sans-serif');

  }

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }


}
