<ng-container [ngSwitch]="foreground.type">
  <sc-image-gif-background *ngSwitchCase="animationType.IMAGE"
                           [src]="foreground.src"
                           [class]="'foreground-layer'">
  </sc-image-gif-background>
  <sc-lottie-background *ngSwitchCase="animationType.LOTTIE"
                        [src]="foreground.src"
                        [class]="'foreground-layer'">
  </sc-lottie-background>
</ng-container>
