import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuestionComponent } from './question.component';
import { QuestionSectionComponent } from './question-section/question-section.component';
import { AnswerSectionComponent } from './answer-section/answer-section.component';
import { AnswerPromptComponent } from './answer-prompt/answer-prompt.component';
import { HighlightAnswerDirective } from './highlight-answer.directive';
import { AnswerPromptPipe } from './answer-prompt.pipe';
import { CorrectAnswerTooltipComponent } from './correct-answer-tooltip/correct-answer-tooltip.component';
import {LottieComponent} from "ngx-lottie";

@NgModule({
  declarations: [
    QuestionComponent,
    QuestionSectionComponent,
    AnswerSectionComponent,
    AnswerPromptComponent,
    HighlightAnswerDirective,
    AnswerPromptPipe,
    CorrectAnswerTooltipComponent
  ],
    exports: [
        QuestionComponent,
        HighlightAnswerDirective
    ],
    imports: [
        CommonModule,
        LottieComponent
    ]
})
export class QuestionModule {
}
