import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { RootStoreModule } from '@root-store/root-store.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@core/layout/layout.module';
import { CourseModule } from './course/course.module';
import { LanguageModule } from './language/language.module';
import { RouterModule } from '@angular/router';
import { provideLottieOptions } from 'ngx-lottie';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    RootStoreModule,
    BrowserAnimationsModule,
    LayoutModule,
    CourseModule,
    LanguageModule,
    RouterModule.forRoot([])
  ],
  providers: [
    provideLottieOptions({
      player: () => import('lottie-web'),
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function playerFactory() { // add this line
  return import('lottie-web'); // add this line
} // add this line
