import {createReducer, on} from '@ngrx/store';

import * as courseActions from './course.actions';
import {ActiveSlideIndexLevel, CourseState} from '@models/course-state.model';
import { GroupProgress } from '@models/course-progress-data-types.model';
import {calculateCompletedTime} from '@root-store/course/course-store-utils';
import {Course} from '@models/course.model';
import {SlideCategory} from '@models/slide.model';

export const courseFeatureKey = 'courseState';

export const initialState: CourseState = new CourseState();

export const courseReducer = createReducer(
  initialState,

  /************************************************************************
   * init
   */
  on(courseActions.resumeCourse, (state, {courseProgress}) => {
      return {
        ...state,
        courseProgress
      };
    }
  ),
  /************************************************************************
   * Get course by id
   */
  on(courseActions.getCourseById, (state) => {
      return {
        ...state,
        isCourseLoading: true
      };
    }
  ),
  on(courseActions.getCourseByIdSuccess, (state, {course, languageCode, asi, isUnlocked}) => {
      const {courseProgress} = state;
      return {
        ...state,
        ...new CourseState(course, courseProgress, languageCode, asi, isUnlocked),
        isCourseLoaded: true,
      };
    }
  ),

  /************************************************************************
   * Get Languages
   */
  on(courseActions.getLanguagesSuccess, (state, {data}) => {
      return {
        ...state,
        courseLanguages: data.languages,
        // course: {
        //   config: data.config
        // }
      };
    }
  ),


  /************************************************************************
   * Save Slide Progress Data
   */
  on(courseActions.updateSlideProgressData, (state, {slideProgress}) => {
      const {courseProgress, course} = state;
      const {slides} = course as Course
      const {asi: activeSlideId, totalTime, completedTime, slideData} = courseProgress;

      const activeSlide = slides[activeSlideId];
      const completedTimeToUpdate = calculateCompletedTime(slideProgress, completedTime);
      const slideDataCopy = JSON.parse(JSON.stringify(slideData));
      if (slideDataCopy[activeSlideId].category === SlideCategory.GROUP) {
        if (activeSlideId !== slideProgress.id) {
          slideDataCopy[slideProgress.id] = slideProgress;
        }
        const isAllContentsAreUnlocked = (slideDataCopy[activeSlideId] as GroupProgress)
          .cRef
          .every(id => slideDataCopy[id].slideCompleted);
        slideDataCopy[activeSlideId].slideCompleted = isAllContentsAreUnlocked;
        slideDataCopy[activeSlideId].isSlideUnlocked = isAllContentsAreUnlocked;

      }
      // else if (slideDataCopy[activeSlideId].type === CourseSlideType.LEVEL) {
      //
      //   const isAllTilesAreUnlocked = (slideDataCopy[activeSlideId] as LevelProgress)
      //     .tRef
      //     .every(id => slideDataCopy[id].slideCompleted);
      //   slideDataCopy[activeSlideId].slideCompleted = isAllTilesAreUnlocked;
      //   slideDataCopy[activeSlideId].isSlideUnlocked = isAllTilesAreUnlocked;
      //
      // }
      else {
        slideDataCopy[activeSlideId] = slideProgress;
      }

      if (activeSlide.lastItemInLink) {
        const linkDeep = activeSlide.link.flat(1).length;
        const flatIds = activeSlide.link.flat(1);

        const tempTile = flatIds[flatIds.length - 2];
        const tempRoot = flatIds[flatIds.length - 3];

        let isAllChildAreUnlockedInRoot: boolean;

        if ((linkDeep % 2) == 1 && linkDeep != 1) {
          slideDataCopy[tempTile].slideCompleted = true;
          slideDataCopy[tempTile].isSlideUnlocked = true;

          isAllChildAreUnlockedInRoot = (slideDataCopy[tempRoot] as GroupProgress)
            .cRef
            .every(id => slideDataCopy[id].slideCompleted);

          if(isAllChildAreUnlockedInRoot){
            flatIds.forEach((r, index) => {
              if (flatIds.length - 1 > index) {
                slideDataCopy[r].slideCompleted = isAllChildAreUnlockedInRoot;
                slideDataCopy[r].isSlideUnlocked = isAllChildAreUnlockedInRoot;
              }
            })
          }

        }
      }


      return {
        ...state,
        courseProgress: {
          ...courseProgress,
          completedTime: completedTimeToUpdate,
          slideData: {
            ...courseProgress.slideData,
            ...slideDataCopy
          }
        }
      };
    }
  ),

  /************************************************************************
   * Set Active Link Map (actLM)
   */
  on(courseActions.setActiveLinkMap, (state, {asi, cpi}) => {

      const {courseProgress} = state;

      return {
        ...state,
        courseProgress: {
          ...courseProgress,
          cpi,
          asi
        }
      } as CourseState;
    }
  ),

  /************************************************************************
   * Disable back when moving to next slide
   */
  on(courseActions.initNextNavigate, (state) => {
    const {courseProgress} = state;
    return {
      ...state,
      courseProgress: {
        ...courseProgress,
        canGoBack: false,
      }
    };
  }),


  /************************************************************************
   * Complete Course
   */
  on(courseActions.completeCourse, (state) => {

      const {courseProgress} = state;

      return {
        ...state,
        activeSlideIndexLevel: ActiveSlideIndexLevel.Root,
        courseProgress: {
          ...courseProgress,
          isCompleted: true,
          completedTime: state.courseProgress.totalTime,

        }
      } as CourseState;
    }
  ),

  /************************************************************************
   * Show Language Selection
   */
  on(courseActions.showLanguageSelection, (state) => {
      return {
        ...state,
        showLanguageSelection: true
      }
    }
  ),

  /************************************************************************
   * Show Course
   */
  on(courseActions.showCourse, (state) => {
      return {
        ...state,
        showLanguageSelection: false
      }
    }
  ),
);
