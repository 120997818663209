import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ScormService } from '@core/services/scorm.service';
import { isCourseLoaded, showLanguageSelection } from '@root-store/course/course.selectors';
import { initializeCourse } from '@root-store/course/course.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'sc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnDestroy {

  @HostListener('document:keydown.tab', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    event.preventDefault()
  }

  isCourseLoaded$;
  showLanguageSelection$;

  constructor(private _scormService: ScormService,
              private _store: Store) {
    this._store.dispatch(initializeCourse())
    this.showLanguageSelection$ = this._store.select(showLanguageSelection);
    this.isCourseLoaded$ = this._store.select(isCourseLoaded);

    /* (window as any).onBeforeUnload = () => {
       console.log('onBeforeUnload')
     }*/
  }

  ngOnDestroy() {
    if (this._scormService.isScormEnv) {
      this._scormService.exit()
    }
  }


}
