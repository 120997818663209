import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit} from '@angular/core';
import {CourseFacadeService} from "@core/facades/course.facade.service";
import {
  CancelPlayerFullscreen,
  IsFullscreen,
  PlayerFullscreen
} from "../../../../course/common/player-fullscreen-script";
import {DOCUMENT} from "@angular/common";
import {Observable} from "rxjs";

@Component({
  selector: 'sc-base-controls',
  templateUrl: './base-controls.component.html',
  styleUrls: ['./base-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BaseControlsComponent implements OnInit {

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])

  fullScreenMode() {
    if (document.fullscreenElement == null) {
      this.isFullscreen = false
    }
  }

  isFullscreen: boolean;

  private _elem: HTMLElement;
  private _subtitle: boolean = true
  selectedLanguage$: Observable<string>;

  constructor(@Inject(DOCUMENT) document: Document,
              private _courseFacade: CourseFacadeService,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this._elem = document.documentElement;
    this.selectedLanguage$ = this._courseFacade.selectedLanguage$;
  }

  fullscreen() {
    if (IsFullscreen(document)) {
      this.isFullscreen = CancelPlayerFullscreen(document)
    } else {
      this.isFullscreen = PlayerFullscreen(this._elem)
    }
  }

  toggleSubtitles() {
    this._subtitle = !this._subtitle;
    this._courseFacade.toggleSubtitle(this._subtitle)
  }

  selectLanguage() {
    this._courseFacade.selectLanguage()
  }
}
