import {
  CourseSlideType,
  ExplainerVideo,
  HotspotPointer,
  Level,
  PointerType,
  Slide,
  Slides,
  Tile,
  Video
} from '@models/course.model';

/**
 * common interfaces and types for course progress
 */
export interface SlideProgressData {
  [slideIndex: string]: SlideProgress;
}

export type SlideProgress =
  | QuestionProgress
  | ExplainerProgress
  | VideoProgress
  | EndSlideProgress
  | LevelProgress
  | ImageProgress
  | LayoutProgress
  | AnimatedBackgroundProgress
  | HotspotProgress

export interface TimerConfig {
  run: boolean;
  duration: number;
  from: number;
}

export const localStorageKey = 'sg_course_progress'


/**
 * data types to save in scorm or other LMS api
 */

class SlideProgressBase {
  isSlideUnlocked: boolean = false;
  type: CourseSlideType;
  id: string;
  duration: number = 30;
  slideCompleted = false;
  parentId?: string;

  constructor(slide: Slide) {
    this.type = slide.type;
    this.id = slide.id;
  }
}

/**
 * Question Progress Data
 */
export class TitleProgressData extends SlideProgressBase {
  override duration = 0;
  override isSlideUnlocked = true;
  override id = CourseSlideType.TITLE;
  tileLockSequentially: boolean;

  constructor(slide: Slide) {
    super(slide)
  }
}


/**
 * Question Progress Data
 */
export class QuestionProgress extends SlideProgressBase {
  allowedAttempts?: number;
  attempts: number
  isSelectedAnswerCorrect: boolean = false;
  selectedAnswerIndexes: number[] = [];

  constructor(slide: Slide) {
    super(slide)
  }
}

/**
 * Hotspot Progress Data
 */
export class HotspotProgress extends SlideProgressBase {
  pRef: string[]; // pointer refs (associated pointer id list)
  override duration = 0;
  constructor(slide: Slide) {
    super(slide)
  }
}

/**
 * Pointer Progress Data
 */
export class PointerProgress extends SlideProgressBase {

  cRef: string[] = []; // content refs (associated content id list)
  override duration = 0

  constructor(slide: Slide) {
    super(slide);
  }
}

/**
 * Checkbox Progress Data
 */
export class CheckboxProgress extends SlideProgressBase {
  checked: boolean = false;

  constructor(slide: Slide) {
    super(slide)
  }
}

/**
 * Level Progress Data
 */
export class LevelProgress extends SlideProgressBase {

  tRef: string[]; // tile refs (associated tile id list)
  override duration = 0

  // override isSlideUnlocked = true;

  constructor(slide: Slide) {
    super(slide)
  }
}

/**
 * Tile Progress Data
 */
export class TileProgress extends SlideProgressBase {

  cRef: string[] = []; // content refs (associated content id list)
  tileLockSequentially: boolean;
  override duration = 0

  constructor(slide: Slide) {
    super(slide);
    this.tileLockSequentially = (slide as Tile).tileLockSequentially;
  }
}

/**
 * Video Progress Data
 */
export class VideoProgress extends SlideProgressBase {
  currentTime: number = 0;
  playing: boolean = false;

  constructor(slide: Slide) {
    super(slide);
    if ((slide as Video).videoData) {
      this.duration = (slide as Video).videoData.duration;
    } else {
      this.duration = 0;
      this.isSlideUnlocked = true;
    }

  }
}

/**
 * Image Progress Data
 */
export class ImageProgress extends SlideProgressBase {
  checked: boolean = false;
  override isSlideUnlocked = true;

  constructor(slide: Slide) {
    super(slide);
  }
}

/**
 * Layout Progress Data
 */
export class LayoutProgress extends SlideProgressBase {
  checked: boolean = false;
  override isSlideUnlocked = true;

  constructor(slide: Slide) {
    super(slide);
  }
}

/**
 * Image Progress Data
 */
export class AnimatedBackgroundProgress extends SlideProgressBase {
  checked: boolean = false;
  override isSlideUnlocked = true;

  constructor(slide: Slide) {
    super(slide);
  }
}

/**
 * Explainer Progress Data
 */
export class ExplainerProgress extends SlideProgressBase implements VideoProgress {

  currentTime: number = 0;
  playing: boolean = false;

  constructor(slide: Slide) {
    super(slide);
    if ((slide as ExplainerVideo).explainerData) {
      if ((slide as ExplainerVideo).explainerData.duration) {
        this.duration = (slide as ExplainerVideo).explainerData.duration
      } else {
        (slide as ExplainerVideo).explainerData.scriptBlocks.forEach(scriptBlock => {
          this.duration += scriptBlock.seconds
        })
      }
    } else {
      this.duration = 0;
      this.isSlideUnlocked = true;
    }

  }
}

/**
 * End Slide Progress Data
 */
export class EndSlideProgress extends SlideProgressBase {

  // override duration = 0;

  constructor(slide: Slide) {
    super(slide)
  }
}

/**
 * Slide unit with combined progress data and slide content
 */
export class SlideDataUnit<T extends Slide = Slide> {
  type: CourseSlideType;
  slideProgress: SlideProgress;
  slide: T

  constructor(slide: T, slideProgress: SlideProgress) {
    this.slide = slide;
    this.slideProgress = slideProgress ? JSON.parse(JSON.stringify(slideProgress)) : slideProgress
    this.type = slide.type
  }
}

/**
 * factory class to create class types
 */
export class ProgressDataFactory {

  level: number = 1;

  static createSlideProgressData(slides: Slides, isUnlocked: boolean): SlideProgressData {

    const slideData: SlideProgressData = {};

    Object.values(slides || {}).forEach((rootSlide) => {

      slideData[rootSlide.id || CourseSlideType.TITLE] = ProgressDataFactory
        ._createProgressDataType(
          rootSlide,
          null,
          isUnlocked
        ) as LevelProgress;

      switch (rootSlide.type) {
        case CourseSlideType.LEVEL:
          (slideData[rootSlide.id] as LevelProgress).tRef = Object.keys(rootSlide.tiles);
          break;

        case CourseSlideType.TILE:
          (slideData[rootSlide.id] as TileProgress).isSlideUnlocked = slides[rootSlide.id].firstItemInLink;
          (slideData[rootSlide.id] as TileProgress).cRef = Object.keys(rootSlide.content);
          break;

        case CourseSlideType.HOTSPOT:
          (slideData[rootSlide.id] as HotspotProgress).pRef = Object.keys(rootSlide.pointers);
          break;

        case CourseSlideType.POINTER:
          (slideData[rootSlide.id] as PointerProgress).isSlideUnlocked = slides[rootSlide.id].firstItemInLink;
          if((rootSlide as HotspotPointer).pointerType === PointerType.NESTED) {
            (slideData[rootSlide.id] as PointerProgress).cRef = Object.keys(rootSlide.content);
          }
          break;
      }

    });

    return slideData;
  }

  private static _createProgressDataType(slide: Slide, parentId: string | null, isUnlocked?: boolean): SlideProgressBase | null {

    let slideProgressData: SlideProgressBase;

    switch (slide.type) {
      case CourseSlideType.TITLE:
        slideProgressData = new TitleProgressData(slide);
        break;

      case CourseSlideType.QUESTION:
        slideProgressData = new QuestionProgress(slide);
        break;

      case CourseSlideType.HOTSPOT:
        slideProgressData = new HotspotProgress(slide);
        break;

      case CourseSlideType.POINTER:
        slideProgressData = new PointerProgress(slide);
        break;

      case CourseSlideType.LEVEL:
        slideProgressData = new LevelProgress(slide);
        break;

      case CourseSlideType.TILE:
        slideProgressData = new TileProgress(slide);
        break;

      case CourseSlideType.EXPLAINER:
        slideProgressData = new ExplainerProgress(slide);
        if (isUnlocked) {
          (slideProgressData as ExplainerProgress).currentTime = slideProgressData.duration;
        }
        break;

      case CourseSlideType.VIDEO:
        slideProgressData = new VideoProgress(slide);
        if (isUnlocked) {
          (slideProgressData as VideoProgress).currentTime = slideProgressData.duration
        }
        break;

      case CourseSlideType.BACKGROUND_VIDEO:
        slideProgressData = new AnimatedBackgroundProgress(slide)
        break;

      case CourseSlideType.IMAGE:
        slideProgressData = new ImageProgress(slide);
        break

      case CourseSlideType.LAYOUT:
        slideProgressData = new LayoutProgress(slide);
        break

      case CourseSlideType.CHECKBOX:
        slideProgressData = new CheckboxProgress(slide);
        break;

      case CourseSlideType.END:
        return new EndSlideProgress(slide)

      default:
        return null
    }

    if (isUnlocked) {
      slideProgressData.isSlideUnlocked = true;
    }

    return slideProgressData;

  }

  static createSlideDataUnit(activeSlideId: string, slides: Slides, progressData: SlideProgressData): any {

    if (activeSlideId === CourseSlideType.TITLE) {
      return new SlideDataUnit(slides[CourseSlideType.TITLE], progressData[CourseSlideType.TITLE]);
    }

    return new SlideDataUnit(slides[activeSlideId], progressData[activeSlideId]);
  }

  static createGroupChildDataUnitList(childIdList: string[], slides: Slides, progressData: SlideProgressData): any {
    return childIdList
      .filter(childId => !!slides[childId])
      .map(childId => {
        return new SlideDataUnit(slides[childId], progressData[childId]);
      })
  }
}
