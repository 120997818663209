import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sc-pointer',
  templateUrl: './pointer.component.html',
  styleUrls: ['./pointer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PointerComponent {

  @Input() pointerColor: string | null;

}
