import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Course} from "@models/course.model";

@Component({
  selector: 'app-answer-prompt',
  templateUrl: './answer-prompt.component.html',
  styleUrls: ['./answer-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnswerPromptComponent implements OnInit {

  @Input() selectedLanguage: string | null;
  answerPromptMsg: string | null;
  
  constructor() {
  }

  ngOnInit(): void {
  }

}
