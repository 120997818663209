@if (tileSlide().graphic; as tileGraphic) {
  @if (tileGraphic.fileType === 'application/json') {
    <sg-animation [url]="tileGraphic.fileUrl"></sg-animation>
  } @else {
    <sg-base [tileIndex]="'tile' + tileIndex" [url]="tileGraphic.fileUrl"></sg-base>
  }
} @else {
  <sg-base/>
}

@if (tileLockSequentially) {
  <sg-events [lottieAnimation]="'lock.json'"/>
}
@if (isUnlocked) {
  <sg-events [lottieAnimation]="'check-tile.json'"/>
}

<div class="tile-title" [ngClass]="showTitle ? 'tile-title-edit' : ''">
  {{ tileSlide().title }}
</div>

