import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {Layout, LayoutType, Slide} from "@models/course.model";
import {ImageProgress, LayoutProgress, SlideProgress} from "@models/course-progress-data-types.model";
import {Observable} from "rxjs";
import {CourseFacadeService} from "@core/facades/course.facade.service";

@Component({
  selector: 'sc-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements OnInit {
  layout: Layout;
  volume$: Observable<number>

  layoutProgress: LayoutProgress;
  selectedLanguage$: Observable<string>;

  @Input() set layoutSlide(image: Slide | null) {
    this.layout = image as Layout;
    this._cdr.markForCheck();
  }

  @Input() set layoutProgressData(progressData: SlideProgress | null) {
    this.layoutProgress = {...progressData} as ImageProgress;
    if(!progressData?.slideCompleted) {
      this.layoutProgress.slideCompleted = true;
      this.saveProgress.emit(this.layoutProgress)
    }
  }

  @Output() saveProgress: EventEmitter<SlideProgress> = new EventEmitter<SlideProgress>();

  constructor(private _cdr: ChangeDetectorRef,
              private _courseFacade: CourseFacadeService) {
  }

  protected readonly LayoutType = LayoutType;

  ngOnInit(): void {
    this.selectedLanguage$ = this._courseFacade.selectedLanguage$;
    this.volume$ = this._courseFacade.currentVolume$;
  }
}
