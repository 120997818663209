<ng-container [ngSwitch]="background.type">
  <sc-video-background *ngSwitchCase="animationType.VIDEO"
                       [src]="background">
  </sc-video-background>
  <sc-image-gif-background *ngSwitchCase="animationType.IMAGE"
                           [src]="background.src">
  </sc-image-gif-background>
  <sc-lottie-background *ngSwitchCase="animationType.LOTTIE"
                        [src]="background.src">
  </sc-lottie-background>
</ng-container>
