import {
  ChangeDetectionStrategy,
  Component, effect, ElementRef,
  input,
  InputSignal, Signal, viewChild,
} from '@angular/core';
import { Slide, Title } from '@models/course.model';
import videojs from 'video.js';

@Component({
  selector: 'cp-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class TitleComponent {
  htmlVideoElement = viewChild<ElementRef<HTMLVideoElement>>('videoElement');
  titleSlide: InputSignal<Slide<Title>> = input.required();

  constructor() {
    effect(() => {
      if(this.titleSlide() && this.titleSlide().graphic?.fileType === 'video') {
        videojs(this.htmlVideoElement()!.nativeElement, {
          preload: 'auto',
          controlBar: false,
          userActions: {doubleClick: false, click: false},
          autoplay: true,
          loop: true,
          muted: true,
          bigPlayButton: false,
          poster: this.titleSlide().graphic.thumbUrl,
          sources: [{
            src: this.titleSlide().graphic?.assetUrl,
            type: 'video/mp4'
          }]
        });
      }

    })
  }
}
