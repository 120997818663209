import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Video, Slide } from '@models/course.model';
import { CourseFacadeService } from '@core/facades/course.facade.service';
import { SlideProgress, VideoProgress } from '@models/course-progress-data-types.model';
import { VideoData } from '@models/videojs.model';


@Component({
  selector: 'sc-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoComponent implements OnInit {

  videoData: VideoData;

  @Input() set videoSlide(video: Slide | null) {
    if (video) {
      this.videoData = {
        poster: (video as Video).videoData?.thumbUrl,
        duration: (video as Video)?.videoData?.duration,
        sources: [
          {
            type: 'video/mp4',
            src: (video as Video)!.videoData?.url
          }
        ],
        subtitleUrl: (video as Video)?.videoData?.subtitleUrl
      };
    }
  }

  @Input() set videoProgressData(progressData: SlideProgress | null) {
    if (progressData) {
      this.videoProgress = JSON.parse(JSON.stringify(progressData)) as VideoProgress;
    }

  }

  @Output() saveProgress: EventEmitter<SlideProgress> = new EventEmitter<SlideProgress>();

  video: Video;
  videoProgress: VideoProgress;
  volume$: Observable<number>
  subtitle$: Observable<boolean>

  constructor(private _courseFacade: CourseFacadeService) {

  }

  ngOnInit(): void {
    this.volume$ = this._courseFacade.currentVolume$;
    this.subtitle$ = this._courseFacade.toggleSubtitle$;
  }

  /**
   * update data on each time update in 3 secs
   * @param time
   */
  onVideoTimeUpdate(time: number) {
    if (!this.videoProgress.isSlideUnlocked) {
      if (this.videoProgress.currentTime < time) {
        this.videoProgress.currentTime = time;
        this._emitVideoProgressChanges();
      }
    }

    /*if(this.videoProgress.isSlideUnlocked && !this.videoProgress.slideCompleted) {
      this.videoProgress.slideCompleted = true;
      this._emitVideoProgressChanges();
    }*/
  }

  /**
   * on video end update unlock status
   */
  onVideoEnd() {
    if (!this.videoProgress.isSlideUnlocked) {
      this.videoProgress.slideCompleted = true
      this.videoProgress.isSlideUnlocked = true
      this._emitVideoProgressChanges();
    }
  }

  /**
   * read video play stats
   * @param state
   */
  onVideoPlay(state: { currentTime: number, playing: boolean }) {
    let progress = JSON.parse(JSON.stringify(this.videoProgress))
    if (progress.currentTime < state.currentTime) {
      progress.currentTime = state.currentTime;
    }
    progress.playing = state.playing;
    this.saveProgress.emit(progress);

    /*if(this.videoProgress.isSlideUnlocked && !this.videoProgress.slideCompleted) {
      this.videoProgress.slideCompleted = true;
      this._emitVideoProgressChanges();
    }*/
  }

  private _emitVideoProgressChanges() {
    this.saveProgress.emit(this.videoProgress)
  }

}
