import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CourseComponent } from './course.component';
import { SlideModule } from './slide/slide.module';


@NgModule({
  declarations: [
    CourseComponent
  ],
  imports: [
    CommonModule,
    SlideModule
  ],
  exports: [
    CourseComponent
  ]
})
export class CourseModule { }
