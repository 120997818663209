import {Pipe, PipeTransform} from '@angular/core';
import translationData from "@assets/json/translation.json";
import {Translation} from "@models/course.model";

@Pipe({
  name: 'answerPrompt'
})

export class AnswerPromptPipe implements PipeTransform {
  translations: { [key: string]: Translation }

  constructor() {
    this.translations = translationData;
  }

  transform(languageCode: string | null): string {
    if (languageCode) {
      return `${this.translations[languageCode] ? this.translations[languageCode].InvalidPrompt : this.translations['en'].InvalidPrompt}`;
    }
    return 'Invalid Answer, Try again!'
  }

}
