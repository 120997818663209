<section class="hotspot-container" #container>
  <div class="hotspot-pointers">
    <sc-pointer *ngFor="let pointerDataUnit of pointerDataUnits; let i = index"
                [pointerColor]="pointerDataUnit.slide.pointerColor"
                (click)="selectPointer(pointerDataUnit)"
                [class.completed]="pointerDataUnit?.slideProgress?.slideCompleted"
                [pTooltip]="pointerDataUnit.slide.name"
                [style.top]="'calc(' + pointerDataUnit.slide.top + '%'"
                [style.left]="'calc(' + pointerDataUnit.slide.left + '%'"/>
  </div>

  <div class="image-container">

    <ng-container *ngIf="hotspot?.foregroundGraphic">
      <video width="100%" *ngIf="hotspot.foregroundGraphic.fileType == 'video'; else image"
             autoplay
             loop
             [src]="hotspot.foregroundGraphic.assetUrl"
             playsinline>
      </video>
      <ng-template #image>
        <div class="image"
             [style.background-size]="hotspot.isFillScreen? 'cover' : 'contain'"
             [style.background-image]="'url(' + hotspot.foregroundGraphic.assetUrl + ')'">
        </div>
      </ng-template>
    </ng-container>

    <div class="background" *ngIf="hotspot.background" [style.background-image]="'url(' + hotspot.background.url + ')'">
      <ng-lottie *ngIf="hotspot.background.fileType == 'application/json'"
                 [options]="{path: hotspot.background.url}"
                 width="100%"
                 height="100%"/>
    </div>
  </div>

</section>

<!--<ng-container (window:keydown)="navigate($event)">

  <sc-tile *ngFor="let tileDataUnit of tileDataUnits; let i = index"
           [unlockTilesSequentially]="level.unlockTilesSequentially"
           [class.locked]="level.unlockTilesSequentially && !tileDataUnit.slideProgress.isSlideUnlocked"
           [class.completed]="tileDataUnit.slideProgress.slideCompleted"
           (click)="onSelectTile(tileDataUnit.slideProgress)"
           tileDecorator
           [dTileCount]="tileDataUnits.length"
           [dLayout]="level.gridLayout"
           [dTileIndex]="i"
           [tileData]="tileDataUnit"/>

  <div class="background" *ngIf="level.background" [style.background-image]="'url(' + level.background + ')'">
    <ng-lottie *ngIf="level.backgroundType == 'application/json'" [options]="{path: level.background}" width="100%" height="100%"/>
  </div>
  &lt;!&ndash;<div class="placeholder" *ngIf="!levelProgress.isSlideUnlocked && level.info">
    <ng-lottie [options]="{path: 'assets/lottie/arrow-up.json'}"/>
    {{level.info}}
  </div>&ndash;&gt;
</ng-container>-->


