<!--
<ng-container *ngIf="tileIcon; else base">
  <sc-animation *ngIf="tileIcon.fileType === 'application/json'; else image" [url]="tileIcon.assetUrl"></sc-animation>
  <ng-template #image>
    <sc-base [tileIndex]="'tile'+tileIndex" [url]="tileIcon.assetUrl"></sc-base>
  </ng-template>
</ng-container>
<ng-template #base>
  <sc-base></sc-base>
</ng-template>

<sc-events [lottieAnimation]="'check-tile.json'" *ngIf="isCompleted; else lockIcon"></sc-events>
<ng-template #lockIcon>
  <sc-events [lottieAnimation]="'lock.json'" *ngIf="tileLockSequentially"></sc-events>
</ng-template>

<div class="tile-title">
  {{titleTitle}}
</div>

&lt;!&ndash;<sc-events></sc-events>&ndash;&gt;
-->

<section class="tile-container">
  <!-- Show Tile and animation -->
  <ng-container *ngIf="tileData.slide.tileIcon; else base">

    <!--  If tile has animation show Tile Animation -->
    <sc-animation *ngIf="tileData.slide.tileIcon.fileType === 'application/json'; else image"
                  [url]="tileData.slide.tileIcon.assetUrl"/>

    <!--  Else Show image-->
    <ng-template #image>
      <sc-base [url]="tileData.slide.tileIcon.assetUrl"/>
    </ng-template>
  </ng-container>

  <div class="tile-title">
    {{tileData.slide.title}}
  </div>

  <ng-template #base>
    <sc-base></sc-base>
  </ng-template>
  <!--End - Show Tile and animation -->

  <!-- Show Tile Status Icons -->
  <sc-events [lottieAnimation]="'check-tile.json'"
             *ngIf="tileData.slideProgress?.slideCompleted"/>

  <sc-events [lottieAnimation]="'lock.json'"
             *ngIf="unlockTilesSequentially && !tileData.slideProgress?.isSlideUnlocked"/>
  <!-- End -Show Tile Status Icons -->
</section>

