import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SlideComponent } from './slide.component';
import { SlideContainerComponent } from './slide-container/slide-container.component';
import { QuestionModule } from './slide-container/question/question.module';
import { VideoModule } from './slide-container/video/video.module';
import { ExplainerModule } from './slide-container/explainer/explainer.module';
import { CheckboxComponent } from './slide-container/checkbox/checkbox.component';
import { CheckboxModule } from './slide-container/checkbox/checkbox.module';
import { SlideCompletionComponent } from './slide-container/slide-completion/slide-completion.component';
import {
  SlideCompletionTitleComponent
} from './slide-container/slide-completion/slide-completion-title/slide-completion-title.component';
import { LevelModule } from './slide-container/level/level.module';
import { ImageModule } from './slide-container/image/image.module';
import { SlideCompletionPipe } from './slide-container/slide-completion/slide-completion.pipe';
import { AnimatedBackgroundModule } from './slide-container/animated-background/animated-background.module';
import { HotspotComponent } from './slide-container/hotspot/hotspot.component';
import { HotspotItemDirective } from './slide-container/hotspot/hotspot-item.directive';
import {SpinnerModule} from "../common/spinner/spinner.module";
import {TooltipModule} from "primeng/tooltip";
import { PointerComponent } from './slide-container/hotspot/pointer/pointer.component';
import { LayoutComponent } from './slide-container/layout/layout.component';
import { TextLeftComponent } from './slide-container/layout/text-left/text-left.component';
import { TextRightComponent } from './slide-container/layout/text-right/text-right.component';
import { TextTopComponent } from './slide-container/layout/text-top/text-top.component';
import { TextBottomComponent } from './slide-container/layout/text-bottom/text-bottom.component';
import { TextContentTypeDirective } from './slide-container/layout/text-content-type.directive';
import { FullScreenComponent } from './slide-container/layout/full-screen/full-screen.component';
import { LottieComponent } from 'ngx-lottie';
import { TitleComponent } from './slide-container/title/title.component';

@NgModule({
  declarations: [
    SlideComponent,
    SlideContainerComponent,
    CheckboxComponent,
    SlideCompletionComponent,
    SlideCompletionTitleComponent,
    SlideCompletionPipe,
    HotspotComponent,
    HotspotItemDirective,
    PointerComponent,
    LayoutComponent,
    TextLeftComponent,
    TextRightComponent,
    TextTopComponent,
    TextBottomComponent,
    TextContentTypeDirective,
    FullScreenComponent,
  ],
  exports: [
    SlideComponent
  ],
  imports: [
    CommonModule,
    QuestionModule,
    VideoModule,
    ExplainerModule,
    CheckboxModule,
    LevelModule,
    ImageModule,
    AnimatedBackgroundModule,
    SpinnerModule,
    TooltipModule,
    LottieComponent,
    TitleComponent
  ]
})
export class SlideModule {
}
