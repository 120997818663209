import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sc-player-timestamp',
  templateUrl: './player-timestamp.component.html',
  styleUrls: ['./player-timestamp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayerTimestampComponent implements OnInit {

  @Input() duration: number;

  constructor() {
  }

  ngOnInit(): void {
  }

}
