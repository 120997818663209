<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle id="selection" cx="24" cy="24" r="5" [style.fill]="pointerColor || '#169FCC'" [style.stroke]="pointerColor || '#169FCC'" stroke-width="4"/>
  <circle id="circle_1" opacity="0.75" cx="24" cy="24" r="15" [style.stroke]="pointerColor || '#169FCC'" stroke-width="2"/>
  <circle id="circle_2" opacity="0.3" cx="24" cy="24" r="19" [style.stroke]="pointerColor || '#169FCC'" stroke-width="2"/>
  <circle id="circle_3" opacity="0.1" cx="24" cy="24" r="23" [style.stroke]="pointerColor || '#169FCC'" stroke-width="2"/>

  <g id="check">
    <path d="M20.3535 24.6465L23 27" stroke="white" stroke-width="2" stroke-linecap="round"/>
    <path d="M27 22L23 27" stroke="white" stroke-width="2" stroke-linecap="round"/>
  </g>

</svg>
