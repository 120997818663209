import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { LayoutModule } from './layout/layout.module';
import { CourseService } from './services/course.service';
import { CourseProgressDataService } from './services/course-progress-data.service';
import { ScormService } from './services/scorm.service';
import { ConvertSecondsToMinuteStringPipe } from './pipes/convert-seconds-to-minute-string.pipe';
import { TimerService } from './services/timer.service';
import { DialogService } from 'primeng/dynamicdialog';


@NgModule({
  declarations: [
    ConvertSecondsToMinuteStringPipe
  ],
  imports: [
    LayoutModule,
    HttpClientModule,
  ],
  exports: [
    ConvertSecondsToMinuteStringPipe
  ],
  providers: [
    CourseService,
    CourseProgressDataService,
    ScormService,
    TimerService,
    DialogService
  ]
})
export class CoreModule {
}
