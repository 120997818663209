import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {BaseControlsComponent} from './base-controls.component';
import {LanguageControlComponent} from './language-control/language-control.component';
import {VolumeControlComponent} from './volume-control/volume-control.component';
import {FullscreenControlComponent} from './fullscreen-control/fullscreen-control.component';
import {VolumeSliderComponent} from './volume-control/volume-slider/volume-slider.component';
import {SliderModule} from 'primeng/slider';
import {FormsModule} from '@angular/forms';
import {LowVolumeIconComponent} from './volume-control/volume-icon/low-volume-icon/low-volume-icon.component';
import {MediumVolumeIconComponent} from './volume-control/volume-icon/medium-volume-icon/medium-volume-icon.component';
import {HighVolumeIconComponent} from './volume-control/volume-icon/high-volume-icon/high-volume-icon.component';
import {MuteIconComponent} from './volume-control/volume-icon/mute-icon/mute-icon.component';
import {VolumeIconComponent} from './volume-control/volume-icon/volume-icon.component';
import {RouterModule} from "@angular/router";
import {SubtitleControlComponent} from './subtitle-control/subtitle-control.component';
import {TooltipModule} from "primeng/tooltip";
import {TranslationPipe} from "@core/pipes/translation.pipe";


@NgModule({
  declarations: [
    BaseControlsComponent,
    LanguageControlComponent,
    VolumeControlComponent,
    FullscreenControlComponent,
    VolumeSliderComponent,
    LowVolumeIconComponent,
    MediumVolumeIconComponent,
    HighVolumeIconComponent,
    MuteIconComponent,
    VolumeIconComponent,
    SubtitleControlComponent,
  ],
  imports: [
    CommonModule,
    SliderModule,
    FormsModule,
    RouterModule,
    TooltipModule,
    TranslationPipe
  ],
  exports: [
    BaseControlsComponent
  ]
})
export class BaseControlsModule {
}
