import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Answer, Course, Question, Slide} from '@models/course.model';
import {QuestionProgress, SlideProgress} from '@models/course-progress-data-types.model';
import {CourseFacadeService} from "@core/facades/course.facade.service";
import {Observable} from "rxjs";

@Component({
  selector: 'sc-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionComponent {

  @Input() set questionSlide(question: Slide | null) {
    this.question = question as Question;
  }

  @Input() set questionProgressData(progressData: SlideProgress | null) {
    this.questionProgress = {...progressData} as QuestionProgress;
  }

  @Output() saveProgress: EventEmitter<SlideProgress> = new EventEmitter<SlideProgress>();
  @Output() answerSelected = new EventEmitter<Answer>();

  question: Question;
  questionProgress: QuestionProgress;
  selectedLanguage$: Observable<string | null>;
  selectedAnswerIndex: number = -1;
  course$: Observable<Course>;

  constructor(
    private _courseFacade: CourseFacadeService,
  ) {
    this.course$ = _courseFacade.course$;
    this.selectedLanguage$ = _courseFacade.selectedLanguage$
  }

  /**
   *Select Answer
   * @param index
   * @param selectAllCorrectAnswers
   */
  selectAnswer(index: number, selectAllCorrectAnswers: boolean) {

    if (!(this.questionProgress.selectedAnswerIndexes.length > 0 && this.questionProgress.isSlideUnlocked)) {
      this.questionProgress.selectedAnswerIndexes = [
        ...this.questionProgress.selectedAnswerIndexes,
        index
      ];

      if (selectAllCorrectAnswers) {
        this.questionProgress.isSlideUnlocked = this.question.correctAnswersIndex.every(index => (
          this.questionProgress.selectedAnswerIndexes.includes(index))
        );
      } else {
        this.questionProgress.isSlideUnlocked = this.question.correctAnswersIndex.some(index => (
          this.questionProgress.selectedAnswerIndexes.includes(index))
        );
      }

      this.questionProgress.slideCompleted = this.questionProgress.isSlideUnlocked;

      this.questionProgress.isSelectedAnswerCorrect = this.question.correctAnswersIndex.some(i => i === index);
      this.saveProgress.emit(this.questionProgress);
    }

  }

  navigate(event: any) {
    const answersCount = this.question.answers.length;
    switch (event.code) {
      case 'ArrowDown':
      case 'Tab':
        this.selectedAnswerIndex = (this.selectedAnswerIndex + 1) % answersCount;
        break;
      case 'ArrowUp':
        this.selectedAnswerIndex = (this.selectedAnswerIndex - 1 + answersCount) % answersCount;
        break;
      case 'Enter':
      case 'Space':
        this.selectAnswer(this.selectedAnswerIndex, this.question.selectAllCorrectAnswers);
        break;
    }
  }
}

