import {ChangeDetectionStrategy, Component, input, Input, InputSignal} from '@angular/core';
import {TileSlide} from "@models/slide.model";
import {AnimationComponent} from "./animation/animation.component";
import {BaseComponent} from "./base/base.component";
import {EventsComponent} from "./events/events.component";
import {NgClass} from "@angular/common";

@Component({
  selector: 'sg-tile-slide',
  standalone: true,
  imports: [
    AnimationComponent,
    BaseComponent,
    EventsComponent,
    NgClass
  ],
  templateUrl: './tile-slide.component.html',
  styleUrl: './tile-slide.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileSlideComponent {

  tileSlide: InputSignal<TileSlide> = input.required();

  @Input() tileLockSequentially: boolean;
  @Input() isUnlocked: boolean;
  @Input() tileIndex: number;
  @Input() showTitle: boolean = false;


}
