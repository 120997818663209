<div class="question-container" (window:keydown)="navigate($event)">
  <div class="question-section-wrapper" *ngIf="question">
    <app-question-section [question]="question.question" [questionTip]="question.questionTip"></app-question-section>
    <app-answer-section *ngFor="let answer of question.answers; let index = index"
                        [scHighlightAnswer]="index"
                        [index]="index"
                        [selectedIndex]="selectedAnswerIndex"
                        [correctAnswerIndexes]="question.correctAnswersIndex"
                        [savedAnswerIndexes]="questionProgress.selectedAnswerIndexes"
                        [answerObject]="answer"
                        (click)="selectAnswer(index, question.selectAllCorrectAnswers)">
    </app-answer-section>
    <app-answer-prompt [selectedLanguage]="selectedLanguage$ | async"
                       *ngIf="questionProgress.selectedAnswerIndexes.length > 0 && !questionProgress.isSelectedAnswerCorrect">
    </app-answer-prompt>
    <sc-correct-answer-tooltip *ngIf="question?.correctAnswerTooltip && questionProgress.isSelectedAnswerCorrect">
      {{question.correctAnswerTooltip}}
    </sc-correct-answer-tooltip>
  </div>
</div>

