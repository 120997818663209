import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Level, Slide, Tile } from '@models/course.model';
import { LevelProgress, SlideDataUnit, SlideProgress, TileProgress } from '@models/course-progress-data-types.model';
import { CourseFacadeService } from '@core/facades/course.facade.service';

@Component({
  selector: 'sc-level',
  templateUrl: './level.component.html',
  styleUrls: ['./level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LevelComponent {

  @Input() set levelSlide(level: Slide | null) {
    const {tiles} = level as Level
    this.tiles = Object.keys(tiles).map(key => tiles[key]);
    this.level = level as Level;
    this.selectedTileIndex = -1

    this.tileDataUnits = this._courseFacade.selectGroupDataUnitList<Tile>(this.level.id) as SlideDataUnit<Tile>[];
  }

  @Input() set levelProgressData(progressData: SlideProgress | null) {

    if(this.levelProgress && progressData?.id !== this.levelProgress?.id) {
      this._updateReqSent = false;
    }

    this.levelProgress = {...progressData} as LevelProgress;
    if (!this._updateReqSent && progressData && !progressData.isSlideUnlocked) {
      this.saveProgress.emit(this.levelProgress);
      this._updateReqSent = true;
    }
  }

  @Output() saveProgress: EventEmitter<SlideProgress> = new EventEmitter<SlideProgress>();

  selectedTileIndex: number = -1;
  levelProgress: LevelProgress;
  level: Level;
  tileDataUnits: SlideDataUnit<Tile>[];

  private _updateReqSent = false;
  private _courseFacade: CourseFacadeService = inject(CourseFacadeService);
  private tiles: Tile[];

  onSelectTile(tileProgress: SlideProgress) {

    if (this.level.unlockTilesSequentially) {
      if (!tileProgress.isSlideUnlocked) {
        return
      }
    }

    this._courseFacade.selectTile(tileProgress.id)
  }

  navigate(event: any) {
    const tileCount = this.tiles.length;

    switch (event.code) {
      case 'ArrowDown':
      case 'Tab':
        this.selectedTileIndex = (this.selectedTileIndex + 1) % tileCount;
        break;
      case 'ArrowUp':
        this.selectedTileIndex = (this.selectedTileIndex - 1 + tileCount) % tileCount;
        break;
      case 'Enter':
      case 'Space':
        this.onSelectTile((this.tileDataUnits[this.selectedTileIndex].slideProgress));
        break;
    }
  }
}
