import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[scHighlightAnswer]'
})
export class HighlightAnswerDirective implements OnChanges{

  @Input('scHighlightAnswer') index: number;
  @Input() correctAnswerIndexes: number[];
  @Input() savedAnswerIndexes: number[];

  constructor(private _renderer: Renderer2,
              private _hostElement: ElementRef) {

  }

  ngOnChanges(changes: SimpleChanges) {
    this._checkAnswer()
  }

  private _checkAnswer() {

    const correctlyAnswered = this.correctAnswerIndexes.every(i => (
      this.savedAnswerIndexes.includes(i)
    ))

    if(this._isAnswered() || correctlyAnswered) {
      this._hostElement.nativeElement.classList.add('answered');
      if(this._isAnswerCorrect()) {
        this._hostElement.nativeElement.classList.add('correct');
      } else if(this._isAnswered()) {
        this._hostElement.nativeElement.classList.add('wrong');
      }
    }
  }

  private _isAnswered(): boolean {
    return this.savedAnswerIndexes.some(i => this.index === i)
  }

  private _isAnswerCorrect() {
    return this.correctAnswerIndexes.some(i => this.index === i)
  }

}
