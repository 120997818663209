import {createAction, props} from '@ngrx/store';
import { Course } from '@models/course.model';
import { SlideProgress } from '@models/course-progress-data-types.model';
import {CourseProgress} from '@models/course-progress.model';
import {InitiateData} from '@models/language.model';

/************************************************************************
 * Read Query Params
 */
export const readQueryParams = createAction(
  '[Course/Init] Read Query Params',
  props<{ courseId?: string, previewMode?: boolean, language: string }>()
);


/************************************************************************
 * init course
 */
export const initializeCourse = createAction(
  '[Course/Init] initialize the course data'
);
export const startCourseFromBeginning = createAction(
  '[Course/Init] Start Course From Beginning'
);
export const resumeCourse = createAction(
  '[Course/Init] Resume Course',
  props<{ courseProgress: CourseProgress }>()
);

/************************************************************************
 * Get Languages
 */
export const getLanguages = createAction(
  '[Language] Get Languages'
);
export const getLanguagesSuccess = createAction(
  '[Language] Get Languages Success',
  props<{ data:  InitiateData}>()
);
export const getLanguagesFailure = createAction(
  '[Language] Get Languages Failure',
  props<{ error: any }>()
);




/************************************************************************
 * Set Course progress
 */
export const setCourseProgress = createAction(
  '[Course/Init] Set Course progress',
  props<{ courseProgress: CourseProgress }>()
);

/************************************************************************
 * select language
 */
export const selectLanguage = createAction(
  '[Course/Language] Select Language',
  props<{ languageCode: string, courseProgress?: CourseProgress }>()
);


/************************************************************************
 * Get course by id
 */
export const getCourseById = createAction(
  '[Course/API] Get Course By Id',
  props<{ courseId: string | null, languageCode: string }>()
);
export const getCourseByIdSuccess = createAction(
  '[Course/API] Get Course By Id Success',
  props<{
    course: Course,
    languageCode: string,
    asi: string
    isUnlocked?: boolean,
  }>()
);
export const getCourseByIdFailure = createAction(
  '[Course/API] Get Course By Id Failure'
);

/************************************************************************
 * Save Course progress data
 */
export const updateSlideProgressData = createAction(
  '[Course/Slide] Update Slide Progress Data',
  props<{ slideProgress: SlideProgress }>()
);

/************************************************************************
 * Navigate to next slide pre init
 */
export const initNextNavigate = createAction(
  '[Course/Nav] Init Next navigation',
  props<{ isAuto: boolean }>()
);

/************************************************************************
 * Navigate to next slide
 */
export const navigateToNextSlide = createAction(
  '[Course/Nav] Navigate to next slide'
);

/************************************************************************
 * Set active link map
 */
export const setActiveLinkMap = createAction(
  '[Course/Nav] Set active link map',
  props<{
    cpi: number,
    asi: string;
  }>()
);

/************************************************************************
 * Navigate to previous slide
 */
export const navigateToPreviousSlide = createAction(
  '[Course/Nav] Navigate to previous slide'
);

/************************************************************************
 * Select tile
 */
export const selectTile = createAction(
  '[Course/Nav] Select Tile',
  props<{ levelId: string, tileId: string }>()
);


/************************************************************************
 * Save Course Progress Data
 */
export const saveCourseProgressData = createAction(
  '[Course/API] Save Course Progress Data',
  props<{ progressData: CourseProgress }>()
);

/************************************************************************
 * Complete Course
 */
export const completeCourse = createAction(
  '[Course/API] Complete Course'
);

/************************************************************************
 * Show Language Selection
 */
export const showLanguageSelection = createAction(
  '[Course/Nav] Show Language Selection'
);

/************************************************************************
 * Show Course
 */
export const showCourse = createAction(
  '[Course/Nav] Show Course'
);
