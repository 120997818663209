import {createReducer, on} from '@ngrx/store';

import * as courseActions from './course.actions';
import {ActiveSlideIndexLevel, CourseState} from '@models/course-state.model';
import {HotspotProgress, LevelProgress} from '@models/course-progress-data-types.model';
import {calculateCompletedTime} from '@root-store/course/course-store-utils';
import {Course, CourseSlideType, Level} from '@models/course.model';

export const courseFeatureKey = 'courseState';

export const initialState: CourseState = new CourseState();

export const courseReducer = createReducer(
  initialState,

  /************************************************************************
   * init
   */
  on(courseActions.resumeCourse, (state, {courseProgress}) => {
      return {
        ...state,
        courseProgress
      };
    }
  ),
  /************************************************************************
   * Get course by id
   */
  on(courseActions.getCourseById, (state) => {
      return {
        ...state,
        isCourseLoading: true
      };
    }
  ),
  on(courseActions.getCourseByIdSuccess, (state, {course, languageCode,asi, isUnlocked}) => {
      const {courseProgress} = state;
      return {
        ...state,
        ...new CourseState(course, courseProgress, languageCode, asi, isUnlocked),
        isCourseLoaded: true,
      };
    }
  ),

  /************************************************************************
   * Get Languages
   */
  on(courseActions.getLanguagesSuccess, (state, {data}) => {
      return {
        ...state,
        courseLanguages: data.languages,
        // course: {
        //   config: data.config
        // }
      };
    }
  ),


  /************************************************************************
   * Save Slide Progress Data
   */
  on(courseActions.updateSlideProgressData, (state, {slideProgress}) => {

      const {courseProgress, course} = state;
      const {slides} = course as Course
      const {asi: activeSlideId, totalTime, completedTime, slideData} = courseProgress;

      const activeSlide = slides[activeSlideId];
      const completedTimeToUpdate = calculateCompletedTime(slideProgress, completedTime);

      const slideDataCopy = JSON.parse(
        JSON.stringify(slideData)
      );

      if (slideDataCopy[activeSlideId].type === CourseSlideType.HOTSPOT) {
        if (activeSlideId !== slideProgress.id) {
          slideDataCopy[slideProgress.id] = slideProgress;
        }
        const isAllPointersAreUnlocked = (slideDataCopy[activeSlideId] as HotspotProgress)
          .pRef
          .every(id => slideDataCopy[id].slideCompleted);
        slideDataCopy[activeSlideId].slideCompleted = isAllPointersAreUnlocked;
        slideDataCopy[activeSlideId].isSlideUnlocked = isAllPointersAreUnlocked;

      } else if (slideDataCopy[activeSlideId].type === CourseSlideType.LEVEL) {

        const isAllTilesAreUnlocked = (slideDataCopy[activeSlideId] as LevelProgress)
          .tRef
          .every(id => slideDataCopy[id].slideCompleted);
        slideDataCopy[activeSlideId].slideCompleted = isAllTilesAreUnlocked;
        slideDataCopy[activeSlideId].isSlideUnlocked = isAllTilesAreUnlocked;

      } else {
        slideDataCopy[activeSlideId] = slideProgress;
      }

      if (activeSlide.lastItemInLink) {
        const linkDeep = activeSlide.link.flat(1).length;

        const [
          rootId,
          tileId,
          subLevelId,
          subTileId,
          subTileContentId
        ] = activeSlide.link.flat(1);

        switch (linkDeep) {
          case 1:
            break;

          case 2:
            break;

          case 3:
            if (slideProgress.isSlideUnlocked) {
              slideDataCopy[tileId].slideCompleted = true;
              slideDataCopy[tileId].isSlideUnlocked = true;
              let currentTileIndex;
              let nextTileId;
              if (slides[rootId].type == CourseSlideType.LEVEL) {
                if ((slides[rootId] as Level).unlockTilesSequentially && activeSlide.lastItemInLink) {
                  currentTileIndex = (slideDataCopy[rootId] as LevelProgress)
                    .tRef
                    .findIndex(id => tileId === id)

                  slideDataCopy[rootId].tRef;
                  nextTileId = (slideDataCopy[rootId] as LevelProgress)
                    .tRef[currentTileIndex + 1]

                  if ((slides[rootId] as Level).unlockTilesSequentially && nextTileId) {
                    slideDataCopy[nextTileId].isSlideUnlocked = true;
                  }

                }
              }

            }
            break;

          case 4:
            break;

          case 5:
            if (slideProgress.isSlideUnlocked) {
              slideDataCopy[subTileId].slideCompleted = true;
              slideDataCopy[subTileId].isSlideUnlocked = true;

              let currentTileIndex;
              let nextTileId;
              let isAllChildAreUnlockedInRoot;

              if (slideDataCopy[subLevelId].type === CourseSlideType.HOTSPOT) {
                currentTileIndex = (slideDataCopy[subLevelId] as HotspotProgress)
                  .pRef
                  .findIndex(id => subTileId === id)

                slideDataCopy[subLevelId].pRef;
                nextTileId = (slideDataCopy[subLevelId] as HotspotProgress)
                  .pRef[currentTileIndex + 1]

                isAllChildAreUnlockedInRoot = (slideDataCopy[subLevelId] as HotspotProgress)
                  .pRef
                  .every(id => slideDataCopy[id].slideCompleted);

              } else {
                currentTileIndex = (slideDataCopy[subLevelId] as LevelProgress)
                  .tRef
                  .findIndex(id => subTileId === id)

                slideDataCopy[subLevelId].tRef;
                nextTileId = (slideDataCopy[subLevelId] as LevelProgress)
                  .tRef[currentTileIndex + 1]

                if ((slides[subLevelId] as Level).unlockTilesSequentially && nextTileId) {
                  slideDataCopy[nextTileId].isSlideUnlocked = true;
                }

                isAllChildAreUnlockedInRoot = (slideDataCopy[subLevelId] as LevelProgress)
                  .tRef
                  .every(id => slideDataCopy[id].slideCompleted);
              }

              slideDataCopy[tileId].slideCompleted = isAllChildAreUnlockedInRoot;
              slideDataCopy[subLevelId].slideCompleted = isAllChildAreUnlockedInRoot;
            }
            break;

          case 6:
            break;
        }
      }


      return {
        ...state,
        courseProgress: {
          ...courseProgress,
          completedTime: completedTimeToUpdate,
          slideData: {
            ...courseProgress.slideData,
            ...slideDataCopy
          }
        }
      };
    }
  ),

  /************************************************************************
   * Set Active Link Map (actLM)
   */
  on(courseActions.setActiveLinkMap, (state, {asi, cpi}) => {

      const {courseProgress} = state;

      return {
        ...state,
        courseProgress: {
          ...courseProgress,
          cpi,
          asi
        }
      } as CourseState;
    }
  ),

  /************************************************************************
   * Disable back when moving to next slide
   */
  on(courseActions.initNextNavigate, (state) => {
    const {courseProgress} = state;
    return {
      ...state,
      courseProgress: {
        ...courseProgress,
        canGoBack: false,
      }
    };
  }),


  /************************************************************************
   * Complete Course
   */
  on(courseActions.completeCourse, (state) => {

      const {courseProgress} = state;

      return {
        ...state,
        activeSlideIndexLevel: ActiveSlideIndexLevel.Root,
        courseProgress: {
          ...courseProgress,
          isCompleted: true,
          completedTime: state.courseProgress.totalTime,

        }
      } as CourseState;
    }
  ),

  /************************************************************************
   * Show Language Selection
   */
  on(courseActions.showLanguageSelection, (state) => {
      return {
        ...state,
        showLanguageSelection: true
      }
    }
  ),

  /************************************************************************
   * Show Course
   */
  on(courseActions.showCourse, (state) => {
      return {
        ...state,
        showLanguageSelection: false
      }
    }
  ),
);
