import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AnimationItem, AnimationOptions } from 'ngx-lottie/lib/symbols';

@Component({
  selector: 'sc-animation',
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnimationComponent implements OnInit {

  @Input() url: string;

  options: AnimationOptions;

  animationCreated(animationItem: AnimationItem): void {
  }

  constructor() { }

  ngOnInit(): void {
    this.options = {
      path: this.url
    };
  }

}
