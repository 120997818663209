import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import { ExplainerVideo, Slide } from '@models/course.model';
import { Observable, Subject, Subscription } from 'rxjs';
import {map, auditTime} from 'rxjs/operators'
import {ScriptBlock} from '@models/clip-block.model';
import {CourseFacadeService} from "@core/facades/course.facade.service";
import {ExplainerProgress, SlideProgress} from '@models/course-progress-data-types.model';
import { VideoData } from '@models/videojs.model';

@Component({
  selector: 'sc-explainer',
  templateUrl: './explainer.component.html',
  styleUrls: ['./explainer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExplainerComponent implements OnInit {

  explainerData: VideoData;

  @Input() set explainerSlide(explainer: Slide | null) {

    if(explainer) {
      this.explainerData = {
        poster: (explainer as ExplainerVideo)?.explainerData.thumbUrl,
        duration: (explainer as ExplainerVideo)?.explainerData.duration,
        sources: [
          {
            type: 'video/mp4',
            src: (explainer as ExplainerVideo)?.explainerData.fileUrl
          }
        ],
        subtitleUrl: (explainer as ExplainerVideo)?.explainerData.subtitleUrl
      };
    }

    this.explainer = explainer as ExplainerVideo;

    if (this.explainer.explainerData) {
      this.subtitleUrl = this._createSubtitle(this.explainer.explainerData?.scriptBlocks)
    }

  }

  @Input() set explainerProgressData(progressData: SlideProgress | null) {
    this.explainerProgress = {...progressData} as ExplainerProgress;
  }

  @Output() saveProgress: EventEmitter<SlideProgress> = new EventEmitter<SlideProgress>();

  explainerTime$ = new Subject<number>();
  volume$: Observable<number>

  explainer: ExplainerVideo;
  explainerProgress: ExplainerProgress;
  videoUrl: string;
  subtitleUrl: string;
  subtitle$: Observable<boolean>

  private _subscriptions = new Subscription();
  private time = 0;

  constructor(private _courseFacade: CourseFacadeService) {
    this._subscriptions.add(
      this.explainerTime$.pipe(
        auditTime(3000),
        map(time => Math.trunc(time))
      ).subscribe(time => this.onVideoTimeUpdate(time))
    )
  }

  ngOnInit(): void {
    this.subtitle$ = this._courseFacade.toggleSubtitle$;
    this.volume$ = this._courseFacade.currentVolume$
  }

  /**
   * update data on each time update in 3 secs
   * @param time
   */
  onVideoTimeUpdate(time: number) {
    if(this.time != time){
      this.explainerProgress.currentTime = 1;
      this.saveProgress.emit(this.explainerProgress);
      this.time = time;
    }
  }

  /**
   * on explainer end update unlock status
   */
  onVideoEnd() {
    if (!this.explainerProgress.isSlideUnlocked) {
      this.explainerProgress.isSlideUnlocked = true;
      this.saveProgress.emit(this.explainerProgress);
    }
  }

  /**
   * read explainer play stats
   * @param state
   */
  onVideoPlay(state: { currentTime: number, playing: boolean }) {
    this.explainerProgress.playing = state.playing;
    // this.explainerProgress.currentTime = state.currentTime;
    this.saveProgress.emit(this.explainerProgress);
  }

  private _createSubtitle(scriptBlocks: ScriptBlock[] | undefined) {
    let seconds = 0;
    let start: string;
    let end: string;
    let timestamp = '';
    let subtitle = 'WEBVTT\n\n';

    if (scriptBlocks) {
      scriptBlocks.forEach((scriptBlock, index) => {
        if (scriptBlock.type == 'Text') {
          start = this._generateStringTimeStamp(seconds);
          seconds += scriptBlock.seconds;
          end = this._generateStringTimeStamp(seconds);
          timestamp += start + " --> " + end + '\n' + scriptBlock.text + '\n\n'
        } else {
          seconds += scriptBlock.seconds;
        }
      })
      subtitle += timestamp
    }
    const blob = new Blob([subtitle], {type: 'application/x-mpegURL'});
    return window.URL.createObjectURL(blob);
  }

  private _generateStringTimeStamp(totalSeconds: number) {
    let hours: string = (Math.floor(totalSeconds / 3600)).toString();
    totalSeconds %= 3600;
    let minutes: string = (Math.floor(totalSeconds / 60)).toString();
    let seconds: string = (totalSeconds % 60).toString();

    minutes = String(minutes).padStart(2, "0");
    hours = String(hours).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");
    return hours + ":" + minutes + ":" + seconds + ".000"
  }

}
