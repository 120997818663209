<sg-layout-template [layoutType]="checkboxSlide().layoutType"
                    [graphic]="checkboxSlide().graphic">

  <div class="checkbox-container">
    <sc-checkbox [label]="checkboxSlide().conditionText" [(ngModel)]="check" (ngModelChange)="toggleCheck(check)"/>
    @if (checkboxSlide().hasAdditionalText) {
      <div [innerHTML]="checkboxSlide().additionalText.html"></div>
    }
  </div>

</sg-layout-template>
