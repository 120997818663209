import {ChangeDetectionStrategy, Component, inject, input, InputSignal, OnInit, output} from '@angular/core';
import {MediaProgress, SlideProgress} from "@models/course-progress-data-types.model";
import {MediaSlide} from "@models/slide.model";
import {VideoPlayerComponent} from "../../../common/video-player/video-player.component";
import {AsyncPipe, JsonPipe} from "@angular/common";
import {Observable} from "rxjs";
import {CourseFacadeService} from "@core/facades/course.facade.service";

@Component({
  selector: 'sg-media-slide',
  standalone: true,
  imports: [
    VideoPlayerComponent,
    JsonPipe,
    AsyncPipe
  ],
  templateUrl: './media-slide.component.html',
  styleUrl: './media-slide.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaSlideComponent implements OnInit{
  mediaSlide: InputSignal<MediaSlide> = input.required();
  mediaProgress: InputSignal<MediaProgress> = input.required();
  saveProgress = output<SlideProgress>();
  volume$: Observable<number>
  subtitle$: Observable<boolean>

  private _courseFacade = inject(CourseFacadeService)

  ngOnInit(): void {
    this.volume$ = this._courseFacade.currentVolume$;
    this.subtitle$ = this._courseFacade.toggleSubtitle$;
  }

  onVideoTimeUpdate(time: number) {
    if (!this.mediaProgress().isSlideUnlocked) {
      if (this.mediaProgress().currentTime < time) {
        this.mediaProgress().currentTime = time;
        this._emitVideoProgressChanges();
      }
    }

    /*if(this.videoProgress.isSlideUnlocked && !this.videoProgress.slideCompleted) {
      this.videoProgress.slideCompleted = true;
      this._emitVideoProgressChanges();
    }*/
  }

  /**
   * on video end update unlock status
   */
  onVideoEnd() {
    if (!this.mediaProgress().isSlideUnlocked) {
      this.mediaProgress().slideCompleted = true
      this.mediaProgress().isSlideUnlocked = true
      this._emitVideoProgressChanges();
    }
  }

  /**
   * read video play stats
   * @param state
   */
  onVideoPlay(state: { currentTime: number, playing: boolean }) {
    let progress = JSON.parse(JSON.stringify(this.mediaProgress()))
    if (progress.currentTime < state.currentTime) {
      progress.currentTime = state.currentTime;
    }
    progress.playing = state.playing;
    this.saveProgress.emit(progress);

    /*if(this.videoProgress.isSlideUnlocked && !this.videoProgress.slideCompleted) {
      this.videoProgress.slideCompleted = true;
      this._emitVideoProgressChanges();
    }*/
  }

  private _emitVideoProgressChanges() {
    this.saveProgress.emit(this.mediaProgress())
  }

}
