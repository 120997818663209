import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Layout} from "@models/course.model";

@Component({
  selector: 'sc-text-left',
  templateUrl: './text-left.component.html',
  styleUrls: ['./text-left.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextLeftComponent {
  @Input() layout: Layout;
}
