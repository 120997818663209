import {ScriptBlock} from '@models/clip-block.model';
import {Asset} from "@models/asset.model";

export type RootLink = [string];
export type TileLink = [string, string];
export type TileContentLink<T = string | string[]> = [...TileLink, T]
export type LinkMap = |
  RootLink |
  TileLink |
  TileContentLink

export enum Language {
  ENGLISH = 'en',
  SWEDISH = 'sv'
}

export enum Language_Value {
  en = 'English',
  sv = 'Swedish'
}

enum CourseState {
  PUBLISHED = 'Published',
  UNPUBLISHED = 'Unpublished'
}

export enum CourseSlideType {
  TITLE = 'title',

  // dynamic slides
  QUESTION = 'Question',
  HOTSPOT = 'Hotspot',
  CHECKBOX = 'Checkbox',
  EXPLAINER = 'Explainer',
  LAYOUT = 'Layout',
  VIDEO = 'Video',
  IMAGE = 'Image',
  BACKGROUND_VIDEO = 'Background Video',
  LEVEL = 'Level',
  TILE = 'Tile',
  POINTER = 'Pointer',

  // End
  END = 'End'
}

export enum AnimationType {
  LOTTIE = 'lottie',
  IMAGE = 'image',
  VIDEO = 'video'
}

export enum LayoutType {
  TEXT_LEFT = 'text_left',
  TEXT_RIGHT = 'text_right',
  TEXT_TOP = 'text_top',
  TEXT_BOTTOM = 'text_bottom',
  TEXT_CENTER = 'text_center',
  FULL_SCREEN = 'full_screen',
}

export enum TextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum TextContentType {
  NORMAL = 'normal',
  HEADING1 = 'heading1',
  HEADING2 = 'heading2',
  HEADING3 = 'heading3',
  HEADING4 = 'heading4',
  HEADING5 = 'heading5',
}

export interface DynamicSlideItem {
  type: CourseSlideType;
  title: string;
  id: string;
  index: number;
}

export interface CreateCoursePayload {
  name: string;
  config: {
    language: Language,
    color: string
  };
}

export enum SlideCompletionFormat {
  minutes,
  percentage
}

export type Slide<T =
    | Question
  | Hotspot
  | HotspotPointer
  | AnimatedBackground
  | Checkbox
  | Title
  | ExplainerVideo
  | Video
  | Layout
  | ImageSlide
  | Level
  | Tile
  | End> = T;

export interface Slides {
  [id: string]: Slide;
}

export interface Answer {
  answer: string;
}

export interface HotspotAnswer {
  text: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface Link {
  text: string;
  url: string;
}

export interface SlideCommon {
  index: number;
  id: string;
  type: CourseSlideType;
  title: string;
  link: LinkMap;
  lastItemInLink: boolean;
  firstItemInLink: boolean;
}

export interface ExplainerData extends Asset {
  duration: number;
  isExported: boolean;
  scriptBlocks: ScriptBlock[];
  subtitleUrl: string;
}

export interface Title extends SlideCommon {
  type: CourseSlideType.TITLE;
  subTitle: string;
  graphic: {
    fileType: string,
    assetUrl: string
    thumbUrl: string
  };
  logoUrl: string;
}

export interface End extends SlideCommon {
  type: CourseSlideType.END;
  subTitle: string;
  courseName: string;
  text: string;
  subtext: string;
  icon: Image | null;
  subLinksHeaderText: string;
  subLinks: Link[]
}

export interface Question extends SlideCommon {
  type: CourseSlideType.QUESTION;
  question: string;
  answers: Answer[];
  selectAllCorrectAnswers: boolean;
  correctAnswersIndex: number[];
  correctAnswerTooltip: string;
  questionTip: string | undefined;
  infoTriggerType?: 'correct-answer-based' | 'select-first-based'
}

export interface Hotspot extends SlideCommon {
  type: CourseSlideType.HOTSPOT;
  foregroundImage: Image;
  foregroundGraphic: {
    fileType: string,
    assetUrl: string
  };
  background: Image;
  isFillScreen: boolean;
  // background: string | null;
  // backgroundType: string | null;
  // backgroundThumbnail: string | null;

  pointers: {
    [key: string]: HotspotPointer;
  };
}

export interface HotspotPointer extends SlideCommon {
  type: CourseSlideType.POINTER;
  pointerColor: string | null;
  foregroundImage: Image;
  isFillScreen: boolean;
  name: string;
  popupText: string;
  pointerType: PointerType;
  text: string;
  top: number;
  left: number;
  content: Slides;
}

export enum PointerType {
  POPUP = 'popup',
  NESTED = 'nested',
}

export interface Level extends SlideCommon {
  type: CourseSlideType.LEVEL;
  info: string;
  unlockTilesSequentially: boolean;
  gridLayout: boolean;
  background: string | null;
  backgroundType: string | null;
  tiles: {
    [key: string]: Tile;
  };
  title: "The climate challenge"
}

export interface TileIcon {
  assetUrl: string;
  fileName: string;
  id: string;
  thumbUrl: string;
  fileType: string;
}

export interface Tile extends SlideCommon {
  type: CourseSlideType.TILE;
  content: Slides;
  animation: string | null;
  title: string;
  tileLockSequentially: boolean;
  tileIcon: TileIcon | null;
}

export interface Checkbox extends SlideCommon {
  type: CourseSlideType.CHECKBOX;
  linkText: string;
  linkUrl: string;
  conditionText: string;
  conditionPoints: string[];
  image: string;
  selectedImage: Image;
}

export interface Layout extends SlideCommon {
  type: CourseSlideType.LAYOUT;
  layoutType: LayoutType;
  audioUrl: string;
  audioText: string;
  graphic: {
    fileType: string,
    assetUrl: string
  };
  textContent: TextContent[];
  textAlign: TextAlign;
}

/**
 * Text content
 */
export class TextContent {
  text: string;
  type = TextContentType.NORMAL;
}

export interface Image {
  fileName: string;
  url: string;
  fileType: string;
  thumbUrl: string;
}

export interface ExplainerVideo extends SlideCommon {
  type: CourseSlideType.EXPLAINER;
  explainerData: ExplainerData;
  scriptBlocks: ScriptBlock[];
  name: string | undefined;
}

export interface Video extends SlideCommon {
  type: CourseSlideType.VIDEO;
  videoData: {
    url: string;
    thumbUrl: string;
    duration: number;
    subtitleUrl: string;
  };
}

export interface CourseConfig {
  language: Language;
  primaryColor: Language;
  questionPromptMsg: string;
  multipleAnswerLabel: string;
  autoPlaySlides: boolean;
  canSkip: boolean;
  slideCompletionFormat: SlideCompletionFormat
}

export interface ImageSlide extends SlideCommon {
  type: CourseSlideType.IMAGE;
  selectedImage: {
    fileName?: string;
    url: string;
  };
  isFillScreen: boolean;
  audioUrl: string;
  audioText: string;
  links: LinkPosition[];
}

export interface AnimatedBackground extends SlideCommon {
  type: CourseSlideType.BACKGROUND_VIDEO;
  background: BackgroundOption,
  foreground: BackgroundOption
}

export interface BackgroundOption {
  type: string;
  src: string;
  thumbUrl: string;
}

export interface LinkPosition {
  x: number;
  y: number;
  width: number;
  height: number;
  href: string;
}

export interface Course {
  id?: string;
  name: string;
  thumbUrl?: string;
  state?: CourseState;
  slides: Slides;
  config?: CourseConfig;
}

export interface Translation {
  completed:string;
  timeRemaining:string;
  backSlideTooltip:string;
  nextSlideTooltip:string;
  volume:string;
  language:string;
  fullscreen:string;
  subtitle:string;
  closePopup:string;
  InvalidPrompt:string;
}


/*export class CourseFactory {
  static normalizeSlides(slides: Slides): Slides {
    Object.keys(slides).forEach(slideId => {
      if(slides[slideId].type === CourseSlideType.LEVEL) {

      }
    })
  }
}*/




