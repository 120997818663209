import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {AnimatedBackground, Slide} from "@models/course.model";
import {
  AnimatedBackgroundProgress, SlideProgress
} from '@models/course-progress-data-types.model';

@Component({
  selector: 'sc-animated-background',
  templateUrl: './animated-background.component.html',
  styleUrls: ['./animated-background.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnimatedBackgroundComponent {

  animatedBackgroundSlide: AnimatedBackground;
  animatedBackgroundProgress: AnimatedBackgroundProgress;

  @Input() set animatedBackground(backgroundVideo: Slide | null) {
    this.animatedBackgroundSlide = backgroundVideo as AnimatedBackground;
  }

  @Input() set animatedBackgroundProgressData(progressData: SlideProgress | null) {
    this.animatedBackgroundProgress = {...progressData} as AnimatedBackgroundProgress;
    if(!progressData?.slideCompleted) {
      this.animatedBackgroundProgress.slideCompleted = true;
      this.saveProgress.emit(this.animatedBackgroundProgress)
    }
  }

  @Output() saveProgress: EventEmitter<SlideProgress> = new EventEmitter<SlideProgress>();

  constructor() {
  }

}
