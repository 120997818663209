import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutComponent} from './layout.component';
import {PlayerControlsModule} from './player-controls/player-controls.module';
import {LayoutFacadeService} from '@core/layout/layout.facade.service';

@NgModule({
  declarations: [
    LayoutComponent,
  ],
  imports: [
    CommonModule,
    PlayerControlsModule
  ],
  providers: [
    LayoutFacadeService
  ],
  exports: [
    LayoutComponent,
  ]
})
export class LayoutModule {
}
