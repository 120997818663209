<div class="background-animation">
  <ng-lottie [options]="options"></ng-lottie>
</div>
<div class="completion-slide-container">
  <img [src]="'assets/icons/trophy.png'" class="trophy-icon">
  <sc-slide-completion-title *ngIf="end.courseName"
                             [title]="end.courseName"></sc-slide-completion-title>

  <div class="completion-slide-body">
    <h3>{{end.title}}</h3>
    <div class="subLinks" *ngIf="end.subLinks">
      <a *ngFor="let item of end.subLinks" target="_blank" [href]="item.url">{{item.text}}</a>
    </div>
    <h4>
      <span class="bold-body-text">{{end.subTitle}}</span>
    </h4>
  </div>
</div>


