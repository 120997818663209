import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {CourseSlideType} from '@models/course.model';
import {transition, trigger} from '@angular/animations';
import {left, right} from '@core/animations';
import { SlideDataUnit, SlideProgress } from '@models/course-progress-data-types.model';

@Component({
  selector: 'sc-slide-container',
  templateUrl: './slide-container.component.html',
  styleUrls: ['./slide-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slide', [
      transition(':increment', right),
      transition(':decrement', left),
    ]),
  ]
})
export class SlideContainerComponent {


  @Input() slideDataUnit: SlideDataUnit | null;
  @Output() saveProgress: EventEmitter<SlideProgress> = new EventEmitter<SlideProgress>();

  slideType = CourseSlideType;

}
