<div class="text" [ngStyle]="{'textAlign': layout.textAlign}">
  <div class="container">
    <p [scTextContentType]="text.type" *ngFor="let text of layout.textContent">{{text.text}}</p>
  </div>
</div>
<div class="graphic">
  <ng-container *ngIf="layout.graphic">
    <ng-container *ngIf="layout.graphic.fileType === 'video'; else image">
      <video autoplay
             loop
             [src]="layout.graphic.assetUrl"
             playsinline>
      </video>
    </ng-container>

    <ng-template #image>
      <div class="image-container" [style.background-image]="'url(' + layout.graphic.assetUrl + ')'"></div>
    </ng-template>
  </ng-container>
</div>
