import {Pipe, PipeTransform} from '@angular/core';
import {Translation} from "@models/course.model";
import translationData from "@assets/json/translation.json";

@Pipe({
  name: 'TimeRemainingPipe'
})
export class TimeRemainingPipePipe implements PipeTransform {
  translations: { [key: string]: Translation }

  constructor() {
    this.translations = translationData;
  }

  transform(currentTime: number, totalTime: number, languageCode: string | null): string {
    const remainingSecs = totalTime - currentTime;
    const remainingMins = remainingSecs / 60;

    if (!languageCode) {
      return '--:--';
    }

    const translation = this.translations[languageCode] || this.translations['en'];

    if (remainingMins === 0) {
      return translation.completed;
    }

    if (remainingSecs < 60) {
      return `1 ${translation.timeRemaining}`;
    }

    return `${Math.round(remainingMins)} ${translation.timeRemaining}`;
  }

}
