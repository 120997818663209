import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Hotspot, HotspotAnswer, HotspotPointer, Level, Slide, Tile } from '@models/course.model';
import { HotspotProgress, LevelProgress, SlideDataUnit, SlideProgress } from '@models/course-progress-data-types.model';
import { CourseFacadeService } from '@core/facades/course.facade.service';

@Component({
  selector: 'sc-hotspot',
  templateUrl: './hotspot.component.html',
  styleUrls: ['./hotspot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotspotComponent {

  @Input() set hotspotSlide(hotspot: Slide | null) {
    const {pointers} = hotspot as Hotspot
    this.hotspot = hotspot as Hotspot;
    this.selectedPointerIndex = -1

    this.pointerDataUnits = this._courseFacade.selectGroupDataUnitList(this.hotspot.id) as SlideDataUnit<HotspotPointer>[];
  }

  @Input() set hotspotProgressData(progressData: SlideProgress) {

    if(this.hotspotProgress && progressData?.id !== this.hotspotProgress?.id) {
      this._updateReqSent = false;
    }

    this.hotspotProgress = {...progressData} as HotspotProgress;
    this.pointerDataUnits = this._courseFacade.selectGroupDataUnitList(this.hotspot.id) as SlideDataUnit<HotspotPointer>[];
    if (!this._updateReqSent && progressData && !progressData.isSlideUnlocked) {
      this.saveProgress.emit(progressData);
      this._updateReqSent = true;
    }
  }

  @Output() saveProgress: EventEmitter<SlideProgress> = new EventEmitter<SlideProgress>();

  hotspot: Hotspot;
  hotspotProgress: HotspotProgress;
  pointerDataUnits: SlideDataUnit<HotspotPointer>[];
  selectedPointerIndex: number = -1;

  private _updateReqSent = false;
  private _courseFacade: CourseFacadeService = inject(CourseFacadeService);

  selectPointer(pointerDataUnit: SlideDataUnit<HotspotPointer>) {
    this._courseFacade.selectPointer(pointerDataUnit);
  }
}
