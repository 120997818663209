import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnimationOptions} from 'ngx-lottie';

@Component({
  selector: 'sc-checkbox-condition',
  templateUrl: './checkbox-condition.component.html',
  styleUrls: ['./checkbox-condition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxConditionComponent implements OnInit {

  @Input('conditionText') conditionText: string | undefined;
  @Input('checked') checked: boolean | undefined;
  @Input('points') points: string[] | undefined;

  @Output() checkedEvent = new EventEmitter<boolean>();

  options: AnimationOptions = {
    path: 'assets/lottie/check.json',
    loop: false
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  checkedCondition() {
    this.checkedEvent.emit(true)
  }
}
