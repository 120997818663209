import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Link} from "@models/course.model";

@Component({
  selector: 'sc-checkbox-link',
  templateUrl: './checkbox-link.component.html',
  styleUrls: ['./checkbox-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxLinkComponent implements OnInit {

  @Input('link') link: string | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
