import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Layout} from "@models/course.model";

@Component({
  selector: 'sc-text-right',
  templateUrl: './text-right.component.html',
  styleUrls: ['./text-right.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextRightComponent {
  @Input() layout: Layout;
}
