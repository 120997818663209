import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {CourseFacadeService} from "@core/facades/course.facade.service";
import {Observable} from "rxjs";
import {TranslationPipe} from "@core/pipes/translation.pipe";

@Component({
  selector: 'sc-content',
  standalone: true,
  imports: [CommonModule, TranslationPipe],
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentComponent implements OnInit {
  selectedLanguage$: Observable<string>;
  dialogRef = inject(DynamicDialogConfig<{ popupContent: string | null }>);
  private _ref: DynamicDialogRef = inject(DynamicDialogRef);

  constructor(private _courseFacade: CourseFacadeService) {
  }

  close() {
    this._ref.close()
  }

  ngOnInit(): void {
    this.selectedLanguage$ = this._courseFacade.selectedLanguage$;
  }
}
