import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'sc-correct-answer-tooltip',
  templateUrl: './correct-answer-tooltip.component.html',
  styleUrls: ['./correct-answer-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorrectAnswerTooltipComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
