<div class="answer-button" [ngClass]="selectedIndex === index ? 'selected-answer': '' " >
  <div class="answer-number">
    <ng-container *ngIf="!answered">
      {{index + 1}}
    </ng-container>
    <div class="lottie-container" *ngIf="answered">
      <ng-lottie
        [options]="{ path: (answerStatus ? 'assets/lottie/check_green.json': 'assets/lottie/cross_red.json'),  loop: false}">
      </ng-lottie>
    </div>
  </div>
  <div class="answer-text">{{answer.answer}}</div>
</div>

